import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import cl from 'classnames'
import ItemWithFeedback from 'common/layout/ItemWithFeedback'
import Html from 'common/text/Html'
import { SCORING_RESULT_CORRECT, SCORING_RESULT_INCORRECT } from 'core/consts'
import { filter, find } from 'fp/arrays'
import { get, set } from 'fp/objects'
import {
  fallbackTo,
  identity,
  isUndefined,
  matches,
  not,
  takeSecond,
} from 'fp/utils'
import withProps from 'hoc/withProps'
import CheckCircle from 'hss/images/indicators/check-closed-circle.svg'
import { Maybe } from 'monet'
import { useContext, useId } from 'react'
import { XCircle } from 'react-feather'
import { compose } from 'redux'
import {
  itemShape,
  responseItemShape,
} from '../../../interactives/ChartCompletion/utils'
import { interactiveGradingContext } from '../../InteractiveGradingProvider'
import { interactiveContext } from '../../InteractiveProvider'
import ChartCompletion from '../ChartCompletion'
import ManualOverrideGrader from './ManualOverrideGrader'

const Input = props => {
  const {
    item: { id, value },
    responseItem,
  } = props
  const { scoreData, setSaveButtonDisabled, setScoreData } = useContext(
    interactiveGradingContext,
  )
  const responseId = useId()
  const { gradingEnabled } = useContext(interactiveContext)

  const itemScoringResult = get(`result.byId.${id}`)(scoreData)
  const manualScoringResult = compose(
    correct =>
      Maybe.fromUndefined(correct)
        .map(x => (x ? SCORING_RESULT_CORRECT : SCORING_RESULT_INCORRECT))
        .orUndefined(),
    get('correct'),
    fallbackTo({}),
    find(matches('id', id)),
    fallbackTo([]),
    get('manualItemScores'),
    fallbackTo({}),
  )(scoreData)

  const handleScoringChange = newValue => {
    const manualItemScores = compose(
      isUndefined(newValue)
        ? identity
        : arr => [...arr, { id, correct: newValue === SCORING_RESULT_CORRECT }],
      filter(compose(not, matches('id', id))),
      fallbackTo([]),
      get('manualItemScores'),
      fallbackTo({}),
    )(scoreData)
    setScoreData(set('manualItemScores', manualItemScores))
    setSaveButtonDisabled(false)
  }

  return (
    <>
      <ItemWithFeedback
        scoringResult={manualScoringResult || itemScoringResult}>
        <Stack gap={2}>
          <div id={responseId}>
            <Html body={responseItem?.value || '(blank)'} />
          </div>
          <div style={{ width: '100%' }}>
            Recommended: <Html body={value} />
          </div>
        </Stack>
      </ItemWithFeedback>
      <ToggleButtonGroup
        aria-describedby={responseId}
        aria-label="Scoring"
        color="primary"
        exclusive
        onChange={compose(handleScoringChange, takeSecond)}
        value={manualScoringResult}
        variant="chart-grading">
        <ToggleButton
          aria-describedby={responseId}
          aria-label="Mark as correct"
          disabled={!gradingEnabled}
          value={SCORING_RESULT_CORRECT}>
          <CheckCircle />
        </ToggleButton>
        <ToggleButton
          aria-describedby={responseId}
          aria-label="Mark as incorrect"
          disabled={!gradingEnabled}
          value={SCORING_RESULT_INCORRECT}>
          <XCircle />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

Input.propTypes = {
  item: itemShape.isRequired,
  responseItem: responseItemShape,
}

const Text = props => {
  const {
    item: { id, isStatic, value },
    responseItem,
  } = props
  const { scoreData } = useContext(interactiveGradingContext)
  const itemScoringResult = get(`result.byId.${id}`)(scoreData)
  return isStatic ? (
    <Html body={value} />
  ) : (
    <ItemWithFeedback scoringResult={itemScoringResult}>
      <Html body={responseItem?.value || '(blank)'} />
    </ItemWithFeedback>
  )
}

Text.propTypes = {
  item: itemShape.isRequired,
  responseItem: responseItemShape,
}

const Renderers = {
  text: Text,
  input: Input,
}

const GradingChartItem = props => {
  const {
    item: { contentType, isStatic },
  } = props
  const Renderer = Renderers[contentType]
  return (
    <Box
      className={cl({ static: isStatic })}
      component="td">
      <Renderer {...props} />
    </Box>
  )
}

GradingChartItem.propTypes = {
  item: itemShape.isRequired,
}

const ChartCompletionGrader = () => (
  <ManualOverrideGrader
    CustomRenderer={withProps(ChartCompletion, {
      ChartItemRenderer: GradingChartItem,
    })}
  />
)

export default ChartCompletionGrader
