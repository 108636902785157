import MuiDivider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

const Divider = styled(
  props => (
    <MuiDivider
      {...props}
      sx={{
        opacity: '0.6',
      }}
    />
  ),
  { name: 'settings-Divider' },
)(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    margin: rem(3.5, 0, 3),
  }),
)

export default Divider
