import type { Theme } from '@mui/material'
import { produce } from 'immer'

const plugin = (theme: Theme) =>
  produce(theme, (/* draft */) => {
    /** **************************************************************************
     *                                                                           *
     *                                 N O T I C E                               *
     *                                                                           *
     * ************************************************************************* *
     *                                                                           *
     * If you've arrived here looking for a way to style interactives, you       *
     * should first try adjusting the configuration data over in:                *
     *                                                                           *
     *                 src/apps/hss/ContentBuilder/consts.js                     *
     *                                                                           *
     * THIS file is meant only as an escape hatch for things that can't be       *
     * easily adjusted over there.                                               *
     *                                                                           *
     ************************************************************************** */
  })

export default plugin
