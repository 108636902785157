import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Well from 'apps/common/layout/Well'
import MaybeDraft from 'common/indicators/Draft'
import Centered from 'common/layout/Centered'
import { CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { noop } from 'fp/utils'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import InsertionContentBanner from 'hss/ContentViewer/Banner/InsertionContentBanner'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import PropTypes from 'prop-types'
import { useContext, useId } from 'react'
import { useSelector } from 'react-redux'
import { getParentFromContentId } from 'selectors/contentViewer'
import { hideForPrint } from 'styling/theming/base/mixins'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import useIsSubmittingAssignment from './useIsSubmittingAssignment'

const contentType = CONTENT_TYPE_SUBSECTION

const LearnBanner = ({ title }) => {
  const { subsection } = useContext(contentViewerContext) || {}

  const includeRightSide = !useIsSubmittingAssignment()

  const section = useSelector(
    getParentFromContentId({
      contentType,
      contentId: subsection?.id,
    }),
  )

  const { isContentIdExcluded = noop, toggleExcludeContentId = noop } =
    useContext(assignmentEditContext) || {}

  const includeButtonDescriptionId = useId()

  return (
    <AppBar
      position="sticky"
      sx={hideForPrint()}>
      <Toolbar variant="appbar">
        <LeftSide />

        <Typography variant="tab-title">
          {title || section?.name}

          {Boolean(section?.teacherEdition) && (
            <span className="greyscale"> - TE</span>
          )}

          <MaybeDraft content={section} />
        </Typography>

        {/* if no right side, include empty div to maintain flex positioning and center the title */}
        {includeRightSide ? <RightSide /> : <div />}
      </Toolbar>

      {Boolean(isContentIdExcluded(subsection?.id)) && (
        <Centered>
          <Well sxProps={{ marginTop: 0 }}>
            <span id={includeButtonDescriptionId}>
              This page is excluded from the assignment.
            </span>
            <Button
              aria-describedby={includeButtonDescriptionId}
              onClick={() => toggleExcludeContentId(subsection.id)}
              variant="tertiary">
              Include Page
            </Button>
          </Well>
        </Centered>
      )}
      <InsertionContentBanner />
    </AppBar>
  )
}

LearnBanner.propTypes = {
  title: PropTypes.string,
}

export default LearnBanner
