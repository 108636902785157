import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import MenuButton from 'common/menus/MenuButton'
import LinkButton from 'common/navigation/links/LinkButton'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import { contentTypeShape, entityIdShape } from 'core/shapes'
import { renameKeys } from 'fp/objects'
import useAbilityCheck from 'hooks/useAbilityCheck'
import PropTypes from 'prop-types'
import { getCreatorRoute } from 'routing/consts'
import DeletionMenuItem from '../DeletionMenuItem'

const CardActionMenu = props => {
  const { contentId, contentSubType, contentType } = props
  const content = renameKeys({ id: 'contentId' }, props)
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)

  return (
    <MenuButton
      label="More Options"
      sx={{
        padding: 0,
        '&:hover': {
          backgroundColor: 'unset',
        },
      }}>
      <MenuItem
        component={LinkButton}
        style={{ width: '100%' }}
        to={getCreatorRoute({ contentType, contentSubType, id: contentId })}>
        Edit
      </MenuItem>

      {Boolean(isContentCreator) && <Divider />}
      {Boolean(isContentCreator) && <DeletionMenuItem content={content} />}
    </MenuButton>
  )
}

CardActionMenu.propTypes = {
  contentId: entityIdShape.isRequired,
  contentType: contentTypeShape.isRequired,
  contentSubType: PropTypes.string,
}

export default CardActionMenu
