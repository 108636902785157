import MenuItem from '@mui/material/MenuItem'
import {
  ABILITY_CONTENT_CREATION,
  ABILITY_SELF_CONTENT_AUTHORING,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import { getLabel } from 'hss/ContentBuilder/utils'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  contentBuilderUrl,
  getTeacherContentAuthoringRoute,
} from 'routing/consts'
import { createdByCurrentUser } from 'selectors/content'
import { FEATURE_FLAG_CUSTOMER_CONTENT } from 'shared/consts'

const AuthoringMenuItem = withAbilityCheck(() => {
  const { navigate } = useNavigation()

  const { content } = useContext(contentViewerContext)

  const handleEdit = () => {
    navigate(getTeacherContentAuthoringRoute(content))
  }

  const editable = useSelector(createdByCurrentUser(content))
  const isContentAuthoringLive = useAbilityCheck(FEATURE_FLAG_CUSTOMER_CONTENT)

  if (!isContentAuthoringLive) return null
  if (!editable) return null

  return content ? (
    <MenuItem onClick={handleEdit}>Edit {getLabel(content)}...</MenuItem>
  ) : null
}, ABILITY_SELF_CONTENT_AUTHORING)

const BuilderMenuItem = withAbilityCheck(() => {
  const { navigate } = useNavigation()

  const { subsection } = useContext(contentViewerContext)

  const { contentType, id } = subsection || {}

  const handleEdit = () => {
    navigate(`${contentBuilderUrl}/curriculum/${contentType}/${id}`)
  }

  return subsection ? (
    <MenuItem onClick={handleEdit}>Edit {getLabel(subsection)}</MenuItem>
  ) : null
}, ABILITY_CONTENT_CREATION)

const EditCurrentContentMenuItem = () => (
  <>
    <AuthoringMenuItem />
    <BuilderMenuItem />
  </>
)

export default EditCurrentContentMenuItem
