import TabContext from '@mui/lab/TabContext'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
// import { BuilderProvider } from 'hss/ContentBuilder/interactives/Form/builderContext'
// import EditorContainer from 'hss/ContentBuilder/Curriculum/Block/EditorContainer'
import BusySpinner from 'common/indicators/BusySpinner'
import { sum } from 'fp/numbers'
import useNavigation from 'hooks/useNavigation'
import { useCallback } from 'react'
import { AuthorProvider, useAuthorContext } from '../AuthorProvider'
import AuthoringForm from './AuthoringForm'
import SaveButton from './SaveButton'
import TabPanels from './TabPanels'
import Tabs, { type TabValue, isTabValue } from './Tabs'

const BlockEditor: React.FC = () => {
  const {
    block,
    creating,
    editing,
    formProps: { contentId },
    split,
  } = useAuthorContext()

  const {
    location: { pathname, hash },
    navigate,
  } = useNavigation()

  const currentTab: TabValue = isTabValue(hash) ? hash : '#attributes'

  const setCurrentTab = useCallback(
    (newValue: TabValue) => {
      navigate(sum(pathname, newValue), { replace: true })
    },
    [navigate, pathname],
  )

  if (!(contentId || creating)) return <div>no contentId</div>

  if (editing && !contentId && !block) return <BusySpinner />

  return (
    <TabContext
      value={
        (currentTab === '#preview' && split ? '#content' : currentTab) ||
        '#content'
      }>
      <AuthoringForm>
        <AppBar
          position="sticky"
          variant="condensed">
          <Toolbar variant="appbar">
            <Tabs setCurrentTab={setCurrentTab} />

            <SaveButton />
          </Toolbar>
        </AppBar>

        <TabPanels currentTab={currentTab} />
      </AuthoringForm>
    </TabContext>
  )
}

type Props = {
  creating?: boolean
  editing?: boolean
}

const Wired = (props: Props) => (
  <AuthorProvider {...props}>
    <BlockEditor />
  </AuthorProvider>
)

export default Wired
