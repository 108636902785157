import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  type TreeItem2SlotProps,
  type UseTreeItem2Status,
  useTreeItemState,
} from '@mui/x-tree-view'
import cl from 'classnames'
import { forwardRef } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

type TreeContentComponentProps = TreeItem2SlotProps & {
  expandable: boolean
  itemId: string
  label: React.ReactNode
  notSelectedIcon?: React.ElementType
  selectedIcon?: React.ElementType
  status: UseTreeItem2Status
}

// TODO: Make sure this is screen reader friendly and follows ARIA guidelines.
// Spoiler alert: It doesn't yet. See also this example as a model to follow.
// https://www.w3.org/WAI/ARIA/apg/example-index/treeview/treeview-1/treeview-1a.html

// (was) Almost an exact copy of the example from the MUI docs,
// this component allows you to expand an item without selecting it.
// (https://mui.com/material-ui/react-tree-view/#contentcomponent-prop)
const TreeContentComponent = forwardRef<
  HTMLDivElement,
  TreeContentComponentProps
>((props, ref) => {
  const { expandable, label, itemId, notSelectedIcon, selectedIcon } = props

  const {
    handleSelection,
    selected,
    expanded,
    disabled,
    focused,
    preventSelection,
    handleExpansion,
  } = useTreeItemState(itemId)

  const SelectionIcon = selected ? selectedIcon : notSelectedIcon

  return (
    <div
      className={cl('MuiTreeItem2-content', {
        'Mui-expanded': expanded,
        'Mui-selected': selected,
        'Mui-focused': focused,
        'Mui-disabled': disabled,
      })}
      ref={ref}>
      <Stack
        alignItems="center"
        direction="row"
        ml={1}>
        {SelectionIcon && (
          <SelectionIcon
            className="selection-icon"
            height={24}
            onClick={handleSelection}
            style={{ marginRight: 12 }}
            width={24}
          />
        )}
        <Stack direction="row">
          <Typography
            className="MuiTreeItem2-label"
            sx={{ cursor: 'pointer' }}
            component="div"
            onClick={e => {
              preventSelection(e)
              handleExpansion(e)
            }}>
            {label}
          </Typography>

          {expandable && (
            <button
              className="MuiTreeItem2-iconContainer"
              onClick={e =>
                handleExpansion(
                  e as unknown as React.MouseEvent<HTMLDivElement>,
                )
              }
              style={{ display: 'block' }}
              tabIndex={-1}
              type="button">
              {expanded ? <ChevronUp /> : <ChevronDown />}
            </button>
          )}
        </Stack>
      </Stack>
    </div>
  )
})

export default TreeContentComponent
