import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import { ABILITY_CONTENT_RESTRICTION } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import type { Content, ContentRestrictionType } from 'reducers/@types/content'
import { Icons, fallback, labels } from './ContentRestrictionControls'

type ContentRestrictionIndicatorProps = {
  className?: string
  content: Content
}

const ContentRestrictionIndicator = withAbilityCheck(
  ({ className, content }: ContentRestrictionIndicatorProps) => {
    const type: ContentRestrictionType =
      content?.data?.contentRestriction?.type || fallback

    return type !== fallback ? (
      <Tooltip
        className={className}
        title={labels[type].description}>
        <Icon
          aria-label={labels[type].description}
          role="img">
          {Icons[type]}
        </Icon>
      </Tooltip>
    ) : null
  },
  ABILITY_CONTENT_RESTRICTION,
)

export default ContentRestrictionIndicator
