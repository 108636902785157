import type { Theme } from '@mui/material'
import { deepMerge } from 'fp/objects'
import components from 'styling/theming/base/components'

const plugin = (theme: Theme) =>
  deepMerge(components(theme), {
    components: {},
  })

export default plugin
