import HFAssetCodeField from 'common/formControls/textInputs/HFAssetCodeField'
import { CONTENT_SUB_TYPE_STANDALONE, CONTENT_TYPE_SOURCE } from 'core/consts'
import withContent from 'hoc/withContent'
import ChildListSection from 'hss/ContentBuilder/Curriculum/ChildListSection'
import CurriculumForm from 'hss/ContentBuilder/Curriculum/CurriculumForm'
import type { Content } from 'reducers/@types/content'

type StructuredSourceProps = {
  content: Content
}

const StructuredSource = (formProps: StructuredSourceProps) => {
  const { content } = formProps

  return (
    <CurriculumForm
      formProps={formProps}
      changingContentStateAllowed={false}
      omitAssetCode
      omitNotes
      omitTerritories
      tagsAllowed={false}
      defaultProps={{}}
      showSharingControls
      sx={{}}>
      {content?.assetCode ? (
        <HFAssetCodeField
          name="assetCode"
          required
          disabled
        />
      ) : null}

      <ChildListSection
        contentTypes={[CONTENT_TYPE_SOURCE]}
        contentSubTypes={[CONTENT_SUB_TYPE_STANDALONE]}
        formProps={formProps}
        name="children"
        title={'Source Content'}
      />
    </CurriculumForm>
  )
}

const StructuredSourceWithContent = withContent(StructuredSource, {
  contentType: CONTENT_TYPE_SOURCE,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default StructuredSourceWithContent
