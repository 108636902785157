import ClassroomEditBreadcrumbs from 'hss/sections/Breadcrumbs/variants/ClassroomEdit'
import ContentAuthorBreadcrumbs from 'hss/sections/Breadcrumbs/variants/ContentAuthor'
import NavBreadcrumbs from 'hss/sections/Breadcrumbs/variants/Nav'
import { useRoutes } from 'react-router-dom'
import {
  classroomUrl,
  contentAuthorUrl,
  contentBuilderUrl,
  libraryUrl,
} from 'routing/consts'
import ContentBuilderBreadcrumbs from 'sections/Breadcrumbs/variants/ContentBuilder'
import CurriculumBuilderBreadcrumbs from 'sections/Breadcrumbs/variants/CurriculumBuilder'
import { CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_RUBRIC } from 'shared/consts'

const config = {
  classroom: {
    element: <ClassroomEditBreadcrumbs />,
    path: `${classroomUrl}/:contentType/:contentType/:contentId/*`,
  },

  contentAuthorEdit: {
    // Use the curriculum breadcrumbs to generate breadcrumbs
    // for child content.
    element: (
      <CurriculumBuilderBreadcrumbs
        rootBreadcrumb={{
          breadcrumb: 'Library',
          key: libraryUrl,
        }}
      />
    ),
    path: `${contentAuthorUrl}/:action/:contentType/type/:contentSubType/:contentId`,
  },

  contentAuthor: {
    element: <ContentAuthorBreadcrumbs />,
    path: `${contentAuthorUrl}/*`,
  },

  contentAuthorInteractive: {
    element: <ContentAuthorBreadcrumbs />,
    path: `${contentAuthorUrl}/:action/${CONTENT_TYPE_INTERACTIVE}/type/:contentSubType/:contentId`,
  },

  contentAuthorRubric: {
    element: <ContentAuthorBreadcrumbs />,
    path: `${contentAuthorUrl}/:action/${CONTENT_TYPE_RUBRIC}/:contentId`,
  },

  curriculum: {
    element: <CurriculumBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/curriculum/:contentType/:contentId`,
  },

  content: {
    element: <ContentBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/:contentGroup/:contentType/:contentId`,
  },

  rubric: {
    element: <ContentBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/:contentType/:contentId`,
  },

  nav: {
    path: '/*',
    element: <NavBreadcrumbs />,
  },
}

const Breadcrumbs = () => {
  const output = useRoutes(Object.values(config))

  return <div>{output}</div>
}

export default Breadcrumbs
