import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import Headline from 'common/text/Headline'
import { set } from 'fp/objects'
import Content from 'hss/sections/contentBlocks/interactives/VennDiagram/Content'
import VennCollapsible from 'hss/sections/contentBlocks/interactives/VennDiagram/VennCollapsible'
import {
  checkForSampleResponse,
  checkForStudentResponse,
} from 'hss/sections/contentBlocks/interactives/VennDiagram/utils'
import { useContext } from 'react'
import Rubric from '../../../interactives/Rubric'
import { interactiveGradingContext } from '../../InteractiveGradingProvider'
import { interactiveContext } from '../../InteractiveProvider'

const VennDiagramRubricGrader = () => {
  const {
    gradingEnabled,
    interactive: { rubric },
    interactiveData,
    interactionData,
    contentId,
  } = useContext(interactiveContext)

  const { scoreData, setSaveButtonDisabled, setScoreData } = useContext(
    interactiveGradingContext,
  )

  const { comment = '', rubricSelections = [] } = scoreData || {}

  const handleRubricChange = newValues => {
    setScoreData(set('rubricSelections', newValues))
    setSaveButtonDisabled(false)
  }

  const handleCommentChange = ({ target: { value } }) => {
    setScoreData(set('comment', value))
    setSaveButtonDisabled(false)
  }

  const hasSampleResponse = checkForSampleResponse(interactiveData)
  const hasStudentResponse = checkForStudentResponse(interactionData)

  return (
    <Stack gap={4}>
      <>
        {hasSampleResponse && (
          <VennCollapsible
            title="Sample Answer"
            initiallyExpanded={false}>
            <Content
              {...interactiveData}
              contentId={contentId}
              readOnly
              showSampleResponse
            />
          </VennCollapsible>
        )}
        <Box>
          <Headline
            hr
            textAlign="center"
            title="Response"
            variant="feature-paragraph-semibold">
            {hasStudentResponse ? (
              <Content
                {...interactiveData}
                {...interactionData}
                contentId={contentId}
                readOnly
              />
            ) : (
              <Box textAlign="center">(no response)</Box>
            )}
          </Headline>
        </Box>
      </>
      <Box component="fieldset">
        <Box
          component="legend"
          style={{ maxWidth: 'none', textAlign: 'center', width: '100%' }}>
          <Headline
            hr
            title="Grading & Review"
            variant="feature-paragraph-semibold"
          />
        </Box>
        <Rubric
          onChange={handleRubricChange}
          readOnly={!gradingEnabled}
          rubric={rubric}
          values={rubricSelections}
        />
        <DebouncedTextField
          disabled={!gradingEnabled}
          label="Optional Comment"
          multiline
          name="comment"
          onChange={handleCommentChange}
          value={comment}
          variant="filled"
        />
      </Box>
    </Stack>
  )
}

export default VennDiagramRubricGrader
