import Link from 'common/navigation/links/Link'
import { ASSIGNMENT_NOT_STARTED, ASSIGNMENT_STARTED } from 'core/consts'
import { asFloatWithoutTrailingZeros } from 'fp/numbers'
import { useContext } from 'react'
import type { ContentType } from 'reducers/@types/content'
import {
  getTeacherAssignmentRoute,
  getTeacherAssignmentUserRoute,
} from 'routing/consts'
import type { TODO } from 'src/@types/utils'
import { gradebookContext } from './GradebookContextProvider'
import RequiresGradingIndicator from './RequiresGradingIndicator'

type AssignmentScoreCellProps = {
  assignmentId: string
  contentType: ContentType
  cell: {
    renderValue: () => TODO
  }
  scoreAccessorFn: (userAssignment: TODO) => number
}

const AssignmentScoreCell = ({
  assignmentId,
  contentType,
  cell: { renderValue },
  scoreAccessorFn,
}: AssignmentScoreCellProps) => {
  const { view } = useContext(gradebookContext)
  const userAssignment = renderValue()
  const {
    gradingStatus,
    id: userAssignmentId,
    requiresGrading,
    userId,
  } = userAssignment

  const scoreValue = scoreAccessorFn(userAssignment)
  if (
    gradingStatus === ASSIGNMENT_NOT_STARTED ||
    scoreValue === undefined ||
    scoreValue === null
  )
    return null

  if (gradingStatus === ASSIGNMENT_STARTED) {
    return (
      <Link to={getTeacherAssignmentRoute({ contentType, id: assignmentId })}>
        —
      </Link>
    )
  }

  return (
    <Link
      to={getTeacherAssignmentUserRoute({
        assignmentId,
        contentType,
        userAssignmentId,
        userId,
      })}>
      {view === 'scores' ? asFloatWithoutTrailingZeros(scoreValue) : scoreValue}
      {Boolean(requiresGrading) && <RequiresGradingIndicator />}
    </Link>
  )
}

export default AssignmentScoreCell
