import Container from '@mui/material/Container'
import FormLabel from '@mui/material/FormLabel'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import {
  getExcludeToolButtons,
  getMaxHeight,
  getMinHeight,
} from 'common/formControls/textInputs/RichTextEdit/utils/misc'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import { INTERACTIVE_TYPE_ESSAY } from 'core/consts'
import { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { useCallback, useContext, useEffect, useId, useState } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import WordBank from './WordBank'

export const statusMessage = {
  [IDLE]: '',
  [BUSY]: 'Saving response…',
  [SUCCESS]: 'Response saved.',
  [ERROR]: 'Error saving response.',
}

const Essay = withQuestionPrompt(() => {
  const {
    interactionData: { response },
    interactiveData: { rteSettings, sampleAnswer, contentWordBank = [] },
    onInteract,
    previewing,
    submittable,
    transportStatus,
  } = useContext(interactiveContext)
  const { numberOfLines = 7, toolButtonOptions } = rteSettings

  const [responseState, setResponseState] = useState(response || '')
  const [showStatus, setShowStatus] = useState()

  const onChange = useCallback(
    ({ target }) => {
      const newValue = target?.value
      setResponseState(newValue)
      onInteract({ response: newValue })
    },
    [onInteract],
  )

  const responseLabelId = useId()

  const showAnswerKey = useIsInAnswerKeyContext() && !previewing

  useEffect(() => {
    if (transportStatus > IDLE) {
      setShowStatus(true)
    }
  }, [transportStatus])

  return (
    <div>
      {showAnswerKey ? (
        sampleAnswer && (
          <Headline
            textAlign="center"
            title="Sample Answer"
            variant="feature-paragraph-semibold">
            <Html body={sampleAnswer} />
          </Headline>
        )
      ) : (
        <Container>
          {submittable ? (
            <>
              {Boolean(contentWordBank.length) && (
                <WordBank
                  contentWordBank={contentWordBank}
                  response={responseState}
                />
              )}
              <FormLabel id={responseLabelId}>Response</FormLabel>
              <RichTextEdit
                ariaLabelledBy={responseLabelId}
                excludeToolButtons={getExcludeToolButtons(toolButtonOptions)}
                maxHeight={getMaxHeight()}
                minHeight={getMinHeight(numberOfLines)}
                onChange={onChange}
                required={!previewing}
                showStatus
                status={statusMessage[transportStatus]}
                sx={{
                  '.draft-statusbar > div': {
                    opacity: showStatus ? 1 : 0,
                    transition: 'all 250ms linear',
                  },
                }}
                value={responseState}
              />
            </>
          ) : (
            <Html body={response || '(no response)'} />
          )}
        </Container>
      )}
    </div>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_ESSAY,
}

export default Essay
