import Html from 'common/text/Html'
import { DND_TYPE_CHART_COMPLETION } from 'core/consts'
import { componentShape } from 'core/shapes'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { forwardRef, useContext } from 'react'
import { useDrag } from 'react-dnd'
import DragItem from '../../DragItem'
import { itemShape } from '../utils'

export const OptionContent = forwardRef(
  ({ children, item, item: { value }, ...rest }, ref) => (
    <DragItem
      {...rest}
      ref={ref}>
      <Html
        body={value}
        allowImageExpansion
      />

      {children}
    </DragItem>
  ),
)

OptionContent.propTypes = {
  children: componentShape,
  item: itemShape.isRequired,
}

const Option = props => {
  const {
    item,
    item: { id },
    ...rest
  } = props

  const { submittable } = useContext(interactiveContext) || {}

  const [, dragRef] = useDrag({
    item,
    type: DND_TYPE_CHART_COMPLETION,
    canDrag: submittable,
  })

  return (
    <OptionContent
      data-testid={`draggable-item-${id}`}
      item={item}
      key={id}
      readOnly={!submittable}
      ref={dragRef}
      {...rest}
    />
  )
}

Option.propTypes = {
  item: itemShape.isRequired,
}

export default Option
