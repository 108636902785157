import DoneIcon from '@mui/icons-material/Done'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Popper from 'common/indicators/Popper'
import Well from 'common/layout/Well'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

const WordBankList = styled(List, { name: 'WordBankList' })(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    padding: 0,

    '& > li': {
      padding: 0,
      margin: rem(0.75),
      width: 'auto',
    },
  }),
)

const WordBank = ({ contentWordBank, response }) => {
  const [open, setOpen] = useState(false)

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const getMatchedWord = wordsArr => {
    let matchedWord = ''
    let minIndex = Number.POSITIVE_INFINITY

    for (const word of wordsArr) {
      const regex = new RegExp(`\\b${word}\\b`, 'i')
      const match = response.match(regex)
      if (match && match.index < minIndex) {
        matchedWord = word
        minIndex = match.index
      }
    }

    return matchedWord
  }

  return (
    <div data-testid="word-bank">
      <Typography variant="h6">Word Bank</Typography>
      <Well padding="loose">
        <WordBankList>
          {contentWordBank.map(words => {
            const { id, primaryWord, variations } = words
            const allVariations = [primaryWord, ...variations]
            const isMatchedWord = getMatchedWord(allVariations)
            const defaultWord = isMatchedWord.length
              ? isMatchedWord
              : primaryWord
            return (
              <ListItem key={id}>
                <Popper
                  disableCloseButton
                  paperStyle={{ minWidth: 200 }}>
                  <Popper.Control>
                    <Box>
                      <Chip
                        icon={isMatchedWord.length ? <DoneIcon /> : null}
                        label={defaultWord}
                        onClick={variations.length ? () => setOpen(id) : null}
                        sx={{
                          backgroundColor: isMatchedWord.length
                            ? 'babyBlue.2'
                            : 'default',
                        }}
                      />
                    </Box>
                  </Popper.Control>
                  {open === id && (
                    <Popper.Content
                      onClose={onClose}
                      open>
                      <Box>
                        {allVariations.map(
                          word =>
                            word !== defaultWord && (
                              <Typography key={word}>{word}</Typography>
                            ),
                        )}
                      </Box>
                    </Popper.Content>
                  )}
                </Popper>
              </ListItem>
            )
          })}
        </WordBankList>
      </Well>
    </div>
  )
}

WordBank.propTypes = {
  contentWordBank: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      primaryWord: PropTypes.string.isRequired,
      variations: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  response: PropTypes.string,
}

export default WordBank
