import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { XCircle as CloseIcon } from 'react-feather'
import { imageAnnotationShape } from './utils'

const StyledContainer = styled('div', { name: 'Image-HighlightRenderer' })(
  ({
    theme: {
      mixins: { transition },
      palette,
      zIndex,
    },
  }) => ({
    position: 'absolute',
    border: `2px dashed ${palette.common.white}`,
    outline: `2px dashed ${palette.common.black}`,
    borderRadius: 4,
    userEvents: 'none',

    '.remove-button': {
      position: 'absolute',
      right: -35,
      top: -35,
      svg: {
        stroke: palette.common.red,
        background: palette.common.white,
        borderRadius: '50%',
      },
      transform: 'scale(1)',
      '&:hover': { transform: 'scale(1.5)' },
      zIndex: zIndex.tooltip,
      ...transition('transform', 120),
    },

    '.read-only-cover': {
      cursor: 'pointer',
      height: '100%',
      width: '100%',
    },
  }),
)

const Highlight = ({ annotation, children, readOnly, removeHighlight }) => {
  const { geometry } = annotation

  return (
    <StyledContainer
      style={{
        top: `${geometry.y}%`,
        left: `${geometry.x}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}>
      {!readOnly && (
        <Tooltip title="Remove highlight">
          <IconButton
            className="remove-button"
            onClick={() => removeHighlight(annotation)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
      {children}
    </StyledContainer>
  )
}

Highlight.propTypes = {
  annotation: imageAnnotationShape.isRequired,
  children: PropTypes.node,
  readOnly: PropTypes.bool.isRequired,
  removeHighlight: PropTypes.func,
}

const HighlightRenderer = ({
  annotation,
  onClick,
  readOnly = false,
  removeHighlight,
}) => (
  <Highlight {...{ annotation, readOnly, removeHighlight }}>
    {!!readOnly && (
      <Tooltip
        title={
          annotation.data?.text ? (
            <div>
              {annotation.data.text.split('\n').map((line, idx) => (
                <div key={idx}>{line}</div>
              ))}
            </div>
          ) : (
            ''
          )
        }
        slotProps={{
          popper: {
            'data-variant': 'image-annotation',
          },
        }}>
        <Box
          className="read-only-cover"
          onClick={onClick}
        />
      </Tooltip>
    )}
  </Highlight>
)

HighlightRenderer.propTypes = {
  annotation: imageAnnotationShape.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  removeHighlight: PropTypes.func,
}

export default HighlightRenderer
