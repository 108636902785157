import MenuItem from '@mui/material/MenuItem'
import { ABILITY_ASSIGNMENT_CREATION, CONTENT_TYPE_CHAPTER } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useContent from 'hooks/useContent'
import useNavigation from 'hooks/useNavigation'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { getLabel } from 'hss/ContentBuilder/utils'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getTeacherAssignmentCreateRoute } from 'routing/consts'
import { getViewerTopLevelContent } from 'selectors/contentViewer'

const AssignContentMenuItem = withAbilityCheck(() => {
  const { contentId: contentIdFromParams, echoId } = useParams()
  const { contentType: topLevelContentType, id: topLevelId } =
    useSelector(getViewerTopLevelContent) || {}

  const contentId = echoId || contentIdFromParams
  const { contentType, contentSubType } =
    useContent({ contentId, disableFetch: true }) || {}
  const { navigate } = useNavigation()
  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  const isEchoWithinNarrativeContent = Boolean(
    echoId && topLevelContentType === CONTENT_TYPE_CHAPTER,
  )

  const handleClick = () => {
    if (isEchoWithinNarrativeContent) {
      // For echoes within chapter content, we do not want to assign the individual Echo, but rather the parent chapter
      navigate(
        getTeacherAssignmentCreateRoute({
          contentType: topLevelContentType,
          id: topLevelId,
        }),
      )
    } else {
      navigate(getTeacherAssignmentCreateRoute({ contentType, id: contentId }))
    }
  }

  return isEditingAssignment ? null : (
    <MenuItem onClick={handleClick}>
      Assign{' '}
      {getLabel({
        contentType: contentType || topLevelContentType,
        contentSubType,
      })}
    </MenuItem>
  )
}, ABILITY_ASSIGNMENT_CREATION)

export default AssignContentMenuItem
