import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import InsertionContentBanner from 'hss/ContentViewer/Banner/InsertionContentBanner'
import PropTypes from 'prop-types'
import RightSide from './RightSide'

const TeachBanner = ({ children, title }) => {
  // make left and right sides same constant width
  // so the title is always centered
  const width = '150px'

  return (
    <AppBar position="sticky">
      <Toolbar variant="appbar">
        <Box width={width} /> {/* left side */}
        <Typography variant="tab-title">{title}</Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={width}>
          <RightSide />
        </Box>
      </Toolbar>
      <InsertionContentBanner />
      {children}
    </AppBar>
  )
}

TeachBanner.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default TeachBanner
