import RichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { getMaxHeight } from 'common/formControls/textInputs/RichTextEdit/utils/misc'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_ESSAY } from 'core/consts'
import withContent from 'hoc/withContent'
import type { ContentType } from 'hss/ContentBuilder/@types/contentBuilder'
import { default as EssayContent } from 'hss/ContentBuilder/interactives/Essay/Content'
import PreviewButton from 'hss/ContentBuilder/interactives/Form/Preview/PreviewButton'
import QuestionPrompt from 'hss/ContentBuilder/interactives/Form/QuestionPrompt'
import EssayInteractive from 'hss/sections/contentBlocks/interactives/Essay'
import type { Content } from 'reducers/@types/content'
import AuthoringInteractiveForm from './AuthoringInteractiveForm'

type EssayFormProps = {
  content: Content
  contentType: ContentType
}

const defaultValues = {
  prompt: '',
  sampleAnswer: null,
  rteSettings: {
    toolButtonOptions: {
      all: false,
      none: false,
      bold: true,
      italic: true,
      underline: true,
      strikethrough: false,
      highlight: false,
      superscript: false,
      subscript: false,
      format: false,
      lists: true,
      link: false,
      indentation: false,
    },
    numberOfLines: 7,
  },
  contentWordBank: [],
}

const previewButton = (props: EssayFormProps) => (
  <PreviewButton
    Interactive={EssayInteractive}
    additionalInteractiveProps={{}}
    {...props}
  />
)

const Essay = (formProps: EssayFormProps) => (
  <AuthoringInteractiveForm
    contentSubType={INTERACTIVE_TYPE_ESSAY}
    defaultValues={defaultValues}
    formProps={formProps}
    previewButton={previewButton}>
    <QuestionPrompt
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      name="prompt"
    />

    <RichTextEdit
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      maxHeight={getMaxHeight()}
      mb={5}
      name="sampleAnswer"
    />

    <EssayContent />
  </AuthoringInteractiveForm>
)

const EssayWithContent = withContent(Essay, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default EssayWithContent
