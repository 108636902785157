import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
} from 'core/consts'
import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import type { ContentType } from 'hss/ContentBuilder/@types/contentBuilder'
import PreviewButton from 'hss/ContentBuilder/interactives/Form/Preview/PreviewButton'
import { default as MultipleChoiceContent } from 'hss/ContentBuilder/interactives/MultipleChoice/Content'
import MultipleChoiceInteractive from 'hss/sections/contentBlocks/interactives/MultipleChoice'
import type { Content } from 'reducers/@types/content'
import { compose } from 'redux'
import AuthoringInteractiveForm from './AuthoringInteractiveForm'

type MultipleChoiceFormProps = {
  content: Content
  contentType: ContentType
}

const newAnswerChoice = () => ({
  id: generateId(),
  label: '',
  explanation: '',
  isCorrect: false,
})

const defaultValues = {
  questionHtml: '',
  allowMultipleSelection: false,
  answerChoices: compose(map(newAnswerChoice), arraySequence)(4),
}

const previewButton = (props: MultipleChoiceFormProps) => (
  <PreviewButton
    Interactive={MultipleChoiceInteractive}
    additionalInteractiveProps={{}}
    {...props}
  />
)

const MultipleChoice = (formProps: MultipleChoiceFormProps) => (
  <AuthoringInteractiveForm
    contentSubType={INTERACTIVE_TYPE_MULTIPLE_CHOICE}
    defaultValues={defaultValues}
    formProps={formProps}
    previewButton={previewButton}>
    <MultipleChoiceContent newAnswerChoice={newAnswerChoice} />
  </AuthoringInteractiveForm>
)

const MultipleChoiceWithContent = withContent(MultipleChoice, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default MultipleChoiceWithContent
