import Form from 'hss/ContentBuilder/interactives/Form'
import { displayText } from '../consts'

const AuthoringInteractiveForm: React.FC<React.ComponentProps<typeof Form>> = ({
  children,
  defaultValues: additionalDefaultValues,
  ...rest
}) => {
  return (
    <Form
      assetCodeDisabled
      assetCodeHelperText={displayText.assetCodeHelperText}
      assetCodeRequired={false}
      defaultValues={{
        sharedWithSchool: false,
        sharedWithDistrict: false,
        ...additionalDefaultValues,
      }}
      changingContentStateAllowed={false}
      nameRequired
      omitNotes
      showSharingControls
      standardsAllowed
      tagsAllowed={false}
      {...rest}>
      {children}
    </Form>
  )
}

export default AuthoringInteractiveForm
