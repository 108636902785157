import Centered from 'common/layout/Centered'
import VennDiagramContent from 'hss/sections/contentBlocks/interactives/VennDiagram/Content'
import VennCollapsible from 'hss/sections/contentBlocks/interactives/VennDiagram/VennCollapsible'
import { checkForSampleResponse } from 'hss/sections/contentBlocks/interactives/VennDiagram/utils'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../../InteractiveProvider'
import withQuestionPrompt from '../../withQuestionPrompt'
import RubricAggregate from './RubricAggregate'

const VennDiagramAggregate = withQuestionPrompt(() => {
  const {
    contentId,
    interactiveData,
    interactive: {
      data: { readOnly },
    },
  } = useContext(interactiveContext)

  const { assignmentId } = useParams()

  const hasSampleResponse = checkForSampleResponse(interactiveData)

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  return (
    <>
      <VennDiagramContent
        {...interactiveData}
        contentId={contentId}
        readOnly
        showSampleResponse={hasSampleResponse}
      />
      {!readOnly && (
        <VennCollapsible title="Average Scores">
          {allInteractions.length ? (
            <RubricAggregate />
          ) : (
            <Centered component="p">(no responses)</Centered>
          )}
        </VennCollapsible>
      )}
    </>
  )
})

export default VennDiagramAggregate
