import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { labels } from 'hss/ContentBuilder/consts'
import { getLabel } from 'hss/ContentBuilder/utils'
import CardIcon from 'hss/views/Card/CardIcon'
import PropTypes from 'prop-types'
import { CONTENT_SUB_TYPE_STANDALONE } from 'shared/consts'

const getLabelForFilter = filter => {
  if (filter === CONTENT_SUB_TYPE_STANDALONE) {
    return getLabel({ contentSubType: CONTENT_SUB_TYPE_STANDALONE })
  }

  return labels[filter]
}

const FilterButtons = ({
  filters,
  handleFilterClick,
  selectedFilters = [],
  subContentType = null,
}) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    gap="2rem"
    my={2}>
    {filters.map(filter => (
      <Button
        color="secondary"
        data-subvariant="filter"
        key={filter}
        onClick={handleFilterClick(filter)}
        startIcon={
          <CardIcon
            contentSubType={subContentType ? filter : null}
            contentType={subContentType ? null : filter}
            variant="lg"
          />
        }
        size="small"
        variant={
          selectedFilters.indexOf(filter) >= 0 ? 'primary' : 'secondary'
        }>
        {getLabelForFilter(filter)}
      </Button>
    ))}
  </Stack>
)

FilterButtons.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  subContentType: PropTypes.string,
}

export default FilterButtons
