import AssetPreviewer from 'common/formControls/selects/AssetUploader/AssetPreviewer'
import { INTERACTIVE_TYPE_DOCUMENT } from 'core/consts'
import { useContext } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Document = () => {
  const {
    uploadsMap: { document } = {},
  } = useContext(interactiveContext) || {}

  return (
    <AssetPreviewer
      uploadType={document.type}
      value={document}
    />
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_DOCUMENT,
}

export default Document
