import { Chip } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Link from 'common/navigation/links/Link'
import Headline, { StyledHeadline } from 'common/text/Headline'
import {
  ABILITY_STUDENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
  INTERACTION_SUBTYPE_IMAGE,
  INTERACTION_SUBTYPE_TEXT,
  INTERACTION_SUBTYPE_VIDEO,
  LEVELED_TEXT_BODY_FIELD_NAME,
  SPANISH_BODY_FIELD_NAME,
  TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT,
} from 'core/consts'
import { contentShape, interactionShape } from 'core/shapes'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useContent from 'hooks/useContent'
import useCurrentUser from 'hooks/useCurrentUser'
import useToggleState from 'hooks/useToggleState'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useCurrentUserShouldSeeLeveledContent } from 'hss/ContentViewer/ContentViewerProvider'
import { formatSec } from 'locale/i18n'
import { buildContentUrl } from 'projections/content'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getContextualAssignment } from 'selectors/assignments'
import { hasAlternateBodiesForSelfOrDescendants } from 'selectors/content'
import { getAnnotationsForContent } from 'selectors/interactions'
import {
  getUserAssignment,
  getUserAssignmentById,
} from 'selectors/userAssignments'
import ImageAnnotation from './ImageAnnotation'

const DisplayAnnotation = ({ annotation, showChip = false }) => {
  const { contentId, interactionData, interactionSubType } = annotation
  const { displayingLeveledContent, displayingSpanishContent } = interactionData

  const userIsTeacher = useAbilityCheck(ABILITY_TEACHER_INTERFACE)

  switch (interactionSubType) {
    case INTERACTION_SUBTYPE_TEXT:
      return (
        <>
          <Typography variant="footnote">
            {interactionData.selectionData.selectedText}
          </Typography>
          <br />
          {interactionData.annotation}
          {displayingLeveledContent && userIsTeacher && showChip && (
            <Chip
              label="LT"
              sx={{ ml: 2 }}
            />
          )}
          {displayingSpanishContent && userIsTeacher && showChip && (
            <Chip
              label="ES"
              sx={{ ml: 2 }}
            />
          )}
        </>
      )
    case INTERACTION_SUBTYPE_IMAGE:
    case INTERACTION_SUBTYPE_VIDEO:
      return (
        <>
          {/* both video and image annotations display an image  */}
          <ImageAnnotation contentId={contentId} />
          {interactionSubType === INTERACTION_SUBTYPE_IMAGE ? (
            <span>{interactionData.data.text}</span>
          ) : (
            <span>
              {formatSec(interactionData.time)} - {interactionData.text}
            </span>
          )}
        </>
      )
    default:
      throw new Error(`Invalid interactionSubType: ${interactionSubType}`)
  }
}

DisplayAnnotation.propTypes = {
  annotation: interactionShape.isRequired,
  showChip: PropTypes.bool,
}

const SubsectionAnnotations = ({
  isGrading = false,
  isLast,
  content,
  currentTab,
  marginTop = 4,
}) => {
  const location = useLocation()
  const userAssignment = useSelector(getUserAssignment) || {}
  const userIsTeacher = useAbilityCheck(ABILITY_TEACHER_INTERFACE)
  const userIsStudent = useAbilityCheck(ABILITY_STUDENT_INTERFACE)
  const { id: assignmentId } = useSelector(getContextualAssignment) || {}
  const url = buildContentUrl('current')(
    content,
    null,
    null,
    null,
    location,
    userAssignment,
  )
  const section = useContent({ contentId: content.parent.id })
  const tabbedSection = useContent({ contentId: section.parent.id })
  const sectionName = section.data?.tabbed
    ? `${tabbedSection.name} - ${section.name}`
    : section.name

  const hasSpanishText = useSelector(
    hasAlternateBodiesForSelfOrDescendants({
      kind: SPANISH_BODY_FIELD_NAME,
      contentId: content.id,
    }),
  )

  const hasLeveledText = useSelector(
    hasAlternateBodiesForSelfOrDescendants({
      kind: LEVELED_TEXT_BODY_FIELD_NAME,
      contentId: content.id,
    }),
  )
  const showingLeveledText = useCurrentUserShouldSeeLeveledContent()

  const [spanishToggled] = useToggleState(
    false,
    TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT,
  )

  const displayingLeveledContent = hasLeveledText && showingLeveledText
  const displayingSpanishContent = hasSpanishText && spanishToggled

  const {
    user: { id: currentUserId },
  } = useCurrentUser()

  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}

  const { userId: studentUserId } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}

  const showTeacherAnnotations =
    !isGrading && (currentTab === 'teacher' || userIsTeacher)

  const annotationsForContent = useSelector(
    getAnnotationsForContent({
      assignmentId: showTeacherAnnotations ? assignmentId : null,
      contentId: content.id,
      userId: userIsStudent
        ? currentUserId
        : isGrading
          ? studentUserId
          : currentUserId,
      includeAlternativeTextAnnotations: userIsTeacher && !isGrading,
      displayingLeveledContent,
      displayingSpanishContent,
      showSharedAnnotations: showTeacherAnnotations,
    }),
  )

  return annotationsForContent?.length > 0 ? (
    <Headline
      component={
        <StyledHeadline
          mt={marginTop}
          size={6}
          sx={{ a: { color: 'text.primary' } }}
          textTransform="capitalize">
          <Link to={url}>{`${sectionName}: ${content.name}`}</Link>
        </StyledHeadline>
      }>
      <ul>
        {annotationsForContent.map(annotation => (
          <li key={annotation.id}>
            <DisplayAnnotation
              annotation={annotation}
              showChip={!isGrading}
            />
          </li>
        ))}
      </ul>
      {!isLast && (
        <Divider
          sx={{
            opacity: '0.6',
          }}
        />
      )}
    </Headline>
  ) : null
}

SubsectionAnnotations.propTypes = {
  content: contentShape.isRequired,
  isLast: PropTypes.bool.isRequired,
  isGrading: PropTypes.bool,
  marginTop: PropTypes.number,
}

export default SubsectionAnnotations
