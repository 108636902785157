import Box from '@mui/material/Box'
import { SimpleTreeView, TreeItem2 } from '@mui/x-tree-view'
import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { difference, filter, flatten, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, matches, matchesOneOf, not } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { useCallback, useContext, useMemo } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import { compose } from 'redux'
import type { TreeEntryContentProps } from 'routing/shells/NavShell/NavSidebar/Nav/TreeEntryContent'
import { getContentNav } from 'selectors/contentViewer'
import { assignmentEditContext } from '../assignmentEditContext'
import TreeContentComponent from './TreeContentComponent'
// import * as R from 'ramda'

const canBeHidden = (item: Content): boolean =>
  matchesOneOf('contentType', [
    CONTENT_TYPE_SECTION,
    CONTENT_TYPE_SUBSECTION,
    CONTENT_TYPE_SOURCE,
  ])(item) && !matches('contentSubType', CONTENT_SUBTYPE_CHAPTER_SUMMARY)(item)

const ContentTreeItem: React.ComponentType<Content> = ({
  children = [],
  id = '',
  name,
}) => {
  return (
    <TreeItem2
      slots={{
        // this \could\ be broken up into separate slots.
        // right now the label, icon, etc are all done within TreeContentComponent
        content: TreeContentComponent,
      }}
      slotProps={
        {
          content: {
            expandable: children.length > 0,
            itemId: id,
            label: name,
            notSelectedIcon: EyeOff,
            selectedIcon: Eye,
          },
        } as unknown as TreeEntryContentProps
      }
      label={name}
      itemId={id}>
      {children.filter(canBeHidden).map(child => (
        <ContentTreeItem
          key={child.id}
          {...child}
        />
      ))}
    </TreeItem2>
  )
}

const flattenChildren = (item: Content): Content[] => [
  item,
  ...flatten(item?.children?.map(flattenChildren)),
]

const Contents = () => {
  const contentId = useWatch({ name: 'contentId' })

  const contentNav = useSelector(
    getContentNav({
      contentId,
      leafContentTypes: SECTION_CONTENT_TYPES,
    }),
  )
  const { setValue } = useFormContext()
  const { excludedContentIds, toggleExcludeContentId } = useContext(
    assignmentEditContext,
  )

  const hidableContents = useMemo<Content[]>(
    () =>
      compose(
        map((i: Content) => ({
          ...i,
          children: i?.children?.filter(canBeHidden),
        })),
        filter(canBeHidden),
        filter(compose(not, matches('teacherEdition', true))),
        fallbackTo([]),
      )(contentNav),
    [contentNav],
  )

  const allContentIds: string[] = useMemo(
    () => flatten((hidableContents || []).map(flattenChildren)).map(get('id')),
    [hidableContents],
  )

  const selectedContentIds: string[] =
    difference(allContentIds)(excludedContentIds)

  useDeepCompareEffect(() => {
    // The excludedContentIds field is actually ignored by the assignment edit form when determining dirtiness.
    // But it should still be marked dirty here, because it's dirty.
    // (This code runs whenever the user toggles a content node.)
    setValue('excludedContentIds', excludedContentIds, { shouldDirty: true })
  }, [excludedContentIds, setValue])

  const handleNodeSelect = useCallback(
    (_: React.SyntheticEvent, itemIds: string[] | null) => {
      if (!itemIds) return
      toggleExcludeContentId(itemIds[0])
    },
    [toggleExcludeContentId],
  )

  return (
    <Box mt={4}>
      {hidableContents.length ? (
        <SimpleTreeView
          multiSelect
          aria-label="Contents"
          onSelectedItemsChange={handleNodeSelect}
          selectedItems={selectedContentIds}
          variant="content-selections">
          {hidableContents.map(content => (
            <ContentTreeItem
              key={content.id}
              {...content}
            />
          ))}
        </SimpleTreeView>
      ) : (
        'This type of assignment does not contain portions that can be hidden.'
      )}
    </Box>
  )
}

Contents.formFields = [
  'excludedContentIds',
  'data.settings.includeChapterSummary',
]

export default Contents
