import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { hasProperty } from 'fp/objects'
import { getLabel } from 'hss/ContentBuilder/utils'
import type React from 'react'
import {
  AlignLeft,
  // BookOpen,
  File,
  Layers,
  List as ListIcon,
  Percent,
  PieChart,
  // Sidebar,
} from 'react-feather'
import type {
  ContentSubType,
  ContentType,
  InteractiveType,
} from 'reducers/@types/content'
import {
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_SUB_TYPE_STRUCTURED,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  // CONTENT_TYPE_CHAPTER,
  // CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
} from 'shared/consts'
import { importantPx } from 'styling/theming/base/mixins'

type AuthoringType = {
  contentType: ContentType
  contentSubType?: ContentSubType | InteractiveType
  icon: React.JSX.Element
}

const authoringPageTypes: Record<string, AuthoringType> = {
  singlePageSource: {
    contentType: CONTENT_TYPE_SOURCE,
    contentSubType: CONTENT_SUB_TYPE_STANDALONE,
    icon: <File />,
  },
  structuredSource: {
    contentType: CONTENT_TYPE_SOURCE,
    contentSubType: CONTENT_SUB_TYPE_STRUCTURED,
    icon: <Layers />,
  },
  // chapter: {
  //   contentType: CONTENT_TYPE_CHAPTER,
  //   icon: <BookOpen />,
  // },
  // echo: {
  //   contentType: CONTENT_TYPE_ECHO,
  //   icon: <Sidebar style={{ transform: 'scaleX(-1)' }} />,
  // },
}

const authoringInteractiveTypes: Record<string, AuthoringType> = {
  essay: {
    contentType: CONTENT_TYPE_INTERACTIVE,
    contentSubType: INTERACTIVE_TYPE_ESSAY,
    icon: <AlignLeft />,
  },
  multipleChoice: {
    contentType: CONTENT_TYPE_INTERACTIVE,
    contentSubType: INTERACTIVE_TYPE_MULTIPLE_CHOICE,
    icon: <ListIcon />,
  },
  poll: {
    contentType: CONTENT_TYPE_INTERACTIVE,
    contentSubType: INTERACTIVE_TYPE_POLL,
    icon: <PieChart />,
  },
  rubric: {
    contentType: CONTENT_TYPE_RUBRIC,
    icon: <Percent />,
  },
}

export const authoringTypes = {
  ...authoringPageTypes,
  ...authoringInteractiveTypes,
}

type Kind = keyof typeof authoringTypes

const ItemRenderer = ({ kind }: { kind: Kind }) => {
  if (!hasProperty(kind)(authoringTypes)) {
    throw new Error(`Invalid kind: ${kind}`)
  }

  const { icon, contentType, contentSubType } = authoringTypes[kind]

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        gap: 1,
        svg: { width: 50, height: 50 },
      }}>
      {icon}
      <Box
        sx={{
          fontSize: importantPx(12),
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {getLabel({ contentType, contentSubType })}
      </Box>
    </Box>
  )
}

type ListRendererProps = {
  selectedType: Kind
  setSelectedType: (kind: Kind) => void
}

const ListRenderer = ({ selectedType, setSelectedType }: ListRendererProps) => (
  <List
    sx={{
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
      justifyContent: 'space-around',
    }}>
    {Object.keys(authoringTypes).map(key => (
      <ListItem
        key={key}
        sx={{
          aspectRatio: 1,
          minWidth: 164,
          maxWidth: 164,
        }}>
        <ListItemButton
          onClick={() => setSelectedType(key)}
          selected={selectedType === key}
          sx={{ minHeight: 120 }}>
          <ItemRenderer kind={key} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
)

const AuthoringTypeSelector = ({
  selectedType,
  setSelectedType,
}: Pick<ListRendererProps, 'selectedType' | 'setSelectedType'>) => (
  <>
    <ListRenderer
      selectedType={selectedType}
      setSelectedType={setSelectedType}
    />
  </>
)

export default AuthoringTypeSelector
