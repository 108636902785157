import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_POLL } from 'core/consts'
import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import type { ContentType } from 'hss/ContentBuilder/@types/contentBuilder'
import PreviewButton from 'hss/ContentBuilder/interactives/Form/Preview/PreviewButton'
import { default as PollContent } from 'hss/ContentBuilder/interactives/Poll/Content'
import PollInteractive from 'hss/sections/contentBlocks/interactives/Poll'
import type { Content } from 'reducers/@types/content'
import { compose } from 'redux'
import AuthoringInteractiveForm from './AuthoringInteractiveForm'

type PollFormProps = {
  content: Content
  contentType: ContentType
}

const newOption = () => ({ id: generateId(), label: '' })
const defaultValues = {
  prompt: '',
  options: compose(map(newOption), arraySequence)(4),
  showSelector: true,
  colsPerRow: 1,
}

const previewButton = (props: PollFormProps) => (
  <PreviewButton
    Interactive={PollInteractive}
    additionalInteractiveProps={{}}
    {...props}
  />
)

const Poll = (formProps: PollFormProps) => (
  <AuthoringInteractiveForm
    contentSubType={INTERACTIVE_TYPE_POLL}
    defaultValues={defaultValues}
    formProps={formProps}
    previewButton={previewButton}>
    <PollContent />
  </AuthoringInteractiveForm>
)

const PollWithContent = withContent(Poll, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default PollWithContent
