import { styled } from '@mui/material/styles'
import cl from 'classnames'
import {
  ASSESSMENT_TYPE_BENCHMARK,
  ASSESSMENT_TYPE_DIAGNOSTIC,
  ASSESSMENT_TYPE_END_OF_UNIT,
  ASSESSMENT_TYPE_READINESS_SCREENER,
  ASSESSMENT_TYPE_TEST_PREP,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_DOCUMENT,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_VIDEO,
} from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import { get } from 'fp/objects'
import { position, size } from 'polished'
import PropTypes from 'prop-types'
import {
  AlignLeft,
  Book,
  Box,
  Camera,
  CheckSquare,
  Columns,
  Copy,
  Edit3,
  File,
  FileText,
  Key,
  List,
  MessageSquare,
  Mic,
  Percent,
  PieChart,
  Video,
} from 'react-feather'

export const cardIcons = {
  AcademicVocabulary: Edit3,
  Assessment: Edit3,
  [ASSESSMENT_TYPE_DIAGNOSTIC]: FileText,
  [ASSESSMENT_TYPE_READINESS_SCREENER]: FileText,
  [ASSESSMENT_TYPE_BENCHMARK]: CheckSquare,
  [ASSESSMENT_TYPE_TEST_PREP]: Edit3,
  [ASSESSMENT_TYPE_END_OF_UNIT]: Book,
  [INTERACTIVE_TYPE_AUDIO]: Mic,
  [CONTENT_TYPE_CHAPTER]: Book,
  [CONTENT_TYPE_ECHO]: MessageSquare,
  Document: File,
  [INTERACTIVE_TYPE_DOCUMENT]: File,
  [INTERACTIVE_TYPE_IMAGE]: Camera,
  PictureThis: MessageSquare,
  Skill: Key,
  Text: FileText,
  [CONTENT_TYPE_UNIT]: Book,
  [CONTENT_TYPE_SECTION]: Columns,
  [CONTENT_TYPE_SOURCE]: File,
  [CONTENT_TYPE_SUBSECTION]: Copy,
  [INTERACTIVE_TYPE_VIDEO]: Video,
  VocabularyPreview: Box,
  [CONTENT_TYPE_INTERACTIVE]: Box,
  [CONTENT_TYPE_RUBRIC]: Percent,
  [INTERACTIVE_TYPE_ESSAY]: AlignLeft,
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: List,
  [INTERACTIVE_TYPE_POLL]: PieChart,

  // TODO: flesh out with other content types
  // These are shadowed in the palettes too
}

const CardIcon = styled(
  ({ className, contentType, contentSubType, customType }) => {
    const itemType = [customType || contentSubType || contentType]
    const Icon = cardIcons[itemType] || Box

    if (!Icon) {
      // biome-ignore lint/suspicious/noConsole: intended
      console.error(`
        Unknown LibraryCard Icon type
        -----------------------------
        customType:     ${customType ? `"${customType}"` : '--'}
        contentSubType: ${contentSubType ? `"${contentSubType}"` : '--'}
        contentType:    ${contentType ? `"${contentType}"` : '--'}
      `)

      return null
    }

    return <Icon className={cl(className, 'library-card-icon', itemType)} />
  },
  { name: 'CardIcon' },
)(({ theme: { palette } }) => ({
  ...position('absolute', null, null, 0, null),
  color: palette.common.white,
  ...size(36),
  padding: 8,
  boxSizing: 'border-box',
  ...Object.entries(cardIcons).reduce(
    (acc, [key]) => ({
      ...acc,
      [`&.${key}`]: { background: get(`libraryItems.${key}`)(palette) },
    }),
    {},
  ),
}))

CardIcon.propTypes = {
  contentSubType: PropTypes.string,
  contentType: contentTypeShape,
  customType: PropTypes.string, // escape hatch for non-content items
}

export default CardIcon
