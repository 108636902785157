import Divider from '@mui/material/Divider'
import ReviewsGiven from 'common/reviews/ReviewsGiven'
import ReviewsReceived from 'common/reviews/ReviewsReceived'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getEchoRubric } from 'selectors/echo'
import YourResponse from './YourResponse'
import useEchoReactions from './useEchoReactions'

const AllReviews = () => {
  const { subsection: echo } = useContext(contentViewerContext) || {}
  const rubricContent = useSelector(getEchoRubric(echo)) || {}
  const rubricCriteria = rubricContent?.children[0]?.data

  const { given, received } = useEchoReactions()

  return (
    <>
      <Divider
        style={{
          marginTop: 48,
          marginBottom: 48,
        }}
        sx={{
          opacity: '0.6',
        }}
      />
      <YourResponse echo={echo} />
      <ReviewsReceived
        {...{
          echo,
          reviews: received,
          rubricCriteria,
        }}
      />
      <ReviewsGiven
        {...{
          echo,
          reviews: given,
          rubricCriteria,
        }}
      />
    </>
  )
}

export default AllReviews
