import Typography from '@mui/material/Typography'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { contentShape } from 'core/shapes'
import { camelCaseToWords, capitalize } from 'fp/strings'
import { labels } from 'hss/ContentBuilder/consts'
import { getLabel } from 'hss/ContentBuilder/utils'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const labeler = compose(
  s => (s === 'D B I' ? 'DBI' : s),
  capitalize,
  camelCaseToWords,
)

const CardLabel = ({ content, customType }) => {
  const { contentSubType, contentType, name } = content

  const firstHalf =
    contentType === CONTENT_TYPE_CHAPTER
      ? content.data?.subtitle
      : labels[contentType]

  return (
    <>
      <Typography
        gutterBottom
        variant="small-semibold">
        {name}
      </Typography>

      <Typography
        component="p"
        variant="small">
        {firstHalf}
        {Boolean(firstHalf) && ' • '}
        {Boolean(contentSubType || customType) &&
          `${getLabel(content) || labeler(customType)}`}
      </Typography>
    </>
  )
}

CardLabel.propTypes = {
  content: contentShape.isRequired,
  customType: PropTypes.string, // escape hatch for non-content items
}

export default CardLabel
