import cl from 'classnames'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import BaseImage from 'common/indicators/Image'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { INTERACTIVE_TYPE_IMAGE } from 'core/consts'
import { when } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import { useCallback, useContext, useEffect, useId, useMemo } from 'react'
import MaximizeIconButton from 'styling/theming/base/components/MaximizeIconButton'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Figure from '../Figure'
import AnnotatableImage from './AnnotatableImage'
import ReadOnlyAnnotations from './AnnotatableImage/ReadOnlyAnnotations'
import Expander from './Expander'
import LongTextAlternative from './LongTextAlternative'
import SensitivityOverlay from './SensitivityOverlay'
import StyledContainer from './StyledContainer'

const Image = () => {
  const {
    contentId,
    interactiveData,
    renderProps: {
      disableMaximizeImage = false,
      embedded = false,
      hideCaption = false,
    } = {},
    setBoosted,
    uploadsMap,
  } = useContext(interactiveContext)

  const {
    allowAnnotations = true,
    allowPanAndZoom = true,
    caption,
    captionPosition,
    forceLongTextVisible = false,
    imageAltText,
    includeSwatch,
    longTextAlternative,
    noGutters,
    scrollHorizontally = false,
    sensitive,
    sensitiveWarning,
  } = interactiveData

  const [showingImage, toggleShowingImage] = useToggleState(!sensitive)
  const [expanded, toggleExpanded] = useToggleState(false)
  useEffect(() => {
    setBoosted(expanded)
  }, [expanded, setBoosted])

  const { up } = useContainerQuery()
  const isLargeEnoughSideCaption = up('md')

  const captionId = useId()
  const descriptionId = useId()
  const src = getImageUrlFromUploadsMap(uploadsMap)

  const handleClick = useCallback(() => {
    when(!disableMaximizeImage && showingImage, toggleExpanded)
  }, [disableMaximizeImage, showingImage, toggleExpanded])

  const builtAltText = longTextAlternative
    ? // https://www.w3.org/WAI/WCAG21/Techniques/general/G74
      `${imageAltText} (details available in text below)`
    : imageAltText

  const imageProps = useMemo(
    () => ({
      alt: builtAltText,
      'aria-describedby': captionId,
      'aria-details': longTextAlternative ? descriptionId : null,
      'aria-hidden': !showingImage,
    }),
    [builtAltText, captionId, descriptionId, longTextAlternative, showingImage],
  )

  return (
    <StyledContainer
      className={
        embedded || hideCaption
          ? cl('image-embedded', 'image-no-gutters')
          : cl('styled-image-container', {
              'image-no-gutters': noGutters,
              'image-scroll-horizontally': scrollHorizontally,
              'image-sensitivity-warning': !showingImage,
            })
      }>
      <Figure
        caption={embedded ? null : caption}
        captionId={captionId}
        captionPosition={
          isLargeEnoughSideCaption
            ? captionPosition
            : captionPosition === 'top'
              ? 'top'
              : 'bottom'
        }
        data-testid="image-figure"
        hideCaption={hideCaption}>
        <div
          className={cl({
            'image-wrapper': true,
            'image-color-swatch': includeSwatch && !embedded,
          })}>
          {!disableMaximizeImage && (
            <MaximizeIconButton onClick={handleClick} />
          )}

          <BaseImage
            alt={builtAltText}
            aria-describedby={captionId}
            aria-details={longTextAlternative ? descriptionId : null}
            aria-hidden={!showingImage}
            onClick={handleClick}
            src={src}
            style={{ cursor: disableMaximizeImage ? 'default' : 'pointer' }}
          />

          {!!showingImage && (
            <ReadOnlyAnnotations
              contentId={contentId}
              onClick={handleClick}
            />
          )}

          {!!sensitive && (
            <SensitivityOverlay
              {...{
                sensitiveWarning,
                showWarning: !showingImage,
                toggleShowingImage,
              }}
            />
          )}
        </div>
      </Figure>

      {Boolean(longTextAlternative) && (
        <LongTextAlternative
          forceVisible={forceLongTextVisible}
          html={longTextAlternative}
          id={descriptionId}
          label="Image Details"
        />
      )}

      <Expander
        expanded={expanded}
        onClick={handleClick}>
        <AnnotatableImage
          {...{
            interactiveAllowsAnnotations: allowAnnotations && !embedded,
            allowPanAndZoom,
            contentId,
            imageProps,
            src,
          }}
        />
      </Expander>
    </StyledContainer>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_IMAGE,
}

export default Image
