import Box from '@mui/material/Box'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import Instructions from 'common/layout/Instructions'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import Html from 'common/text/Html'
import { set } from 'fp/objects'
import useStaticRenderer from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeatureRate/useStaticRenderer'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import { useContext } from 'react'
import { interactiveGradingContext } from 'sections/contentBlocks/Interactive/InteractiveGradingProvider'

const StudentResponseRubricGrader = () => {
  const {
    gradingEnabled,
    interactionData: { response },
    interactive: { contentSubType, rubric },
    interactiveData: { prompt, question, sampleAnswer },
  } = useContext(interactiveContext)

  const { scoreData, setSaveButtonDisabled, setScoreData } = useContext(
    interactiveGradingContext,
  )

  const { comment = '', rubricSelections = [] } = scoreData || {}
  const isEcho = contentSubType?.startsWith('echo')

  const StaticRenderer = useStaticRenderer({ responseType: contentSubType })

  const handleRubricChange = newValues => {
    setScoreData(set('rubricSelections', newValues))
    setSaveButtonDisabled(false)
  }

  const handleCommentChange = ({ target: { value } }) => {
    setScoreData(set('comment', value))
    setSaveButtonDisabled(false)
  }

  return (
    <>
      <Instructions>
        <Html body={question || prompt} />
      </Instructions>

      {Boolean(sampleAnswer) && (
        <CollapsibleSection
          label={
            <Headline
              textAlign="center"
              title="Sample answer"
              variant="feature-paragraph-semibold"
            />
          }
          variant="interactive-grading">
          <HeadlineLevelOffset>
            <Html body={sampleAnswer} />
          </HeadlineLevelOffset>
        </CollapsibleSection>
      )}

      <Box>
        <Headline
          hr
          textAlign="center"
          title="Response"
          variant="feature-paragraph-semibold">
          {isEcho ? <StaticRenderer /> : <Html body={response} />}
        </Headline>
      </Box>

      <Box component="fieldset">
        <Box
          component="legend"
          style={{ maxWidth: 'none', textAlign: 'center', width: '100%' }}>
          <Headline
            hr
            title="Grading & Review"
            variant="feature-paragraph-semibold"
          />
        </Box>
        <Rubric
          onChange={handleRubricChange}
          readOnly={!gradingEnabled}
          rubric={rubric}
          values={rubricSelections}
        />
        <DebouncedTextField
          disabled={!gradingEnabled}
          label="Optional Comment"
          multiline
          name="comment"
          onChange={handleCommentChange}
          value={comment}
          variant="filled"
        />
      </Box>
    </>
  )
}

export default StudentResponseRubricGrader
