import { Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { get } from 'fp/objects'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getInsertedAncestor } from 'selectors/contentViewer'
import { getUserById } from 'selectors/users'
import { isStudent } from 'selectors/users'

type UserState = {
  district: {
    name: string
  }
  firstName: string
  lastName: string
}

type ContentState = {
  districtId: string
  insertionData: {
    userId: string
  }
  modifiedById: string
}

const InsertionContentBanner = () => {
  const content: ContentState = useContext(contentViewerContext)?.content || {}
  const parentContent: ContentState =
    useSelector(getInsertedAncestor(content)) || {}
  const districtId = content.districtId ?? parentContent?.districtId
  const insertionData = content.insertionData ?? parentContent?.insertionData

  const { userId: insertedContentUserId } = insertionData || {}
  const insertionContentUser: UserState | null = useSelector(
    getUserById({ userId: insertedContentUserId }),
  )
  const contentAuthor: UserState | null = useSelector(
    getUserById({ userId: content?.modifiedById }),
  )
  const userIsStudent = useSelector(isStudent)
  const insertionUser = insertionContentUser || contentAuthor
  const { district, firstName, lastName } = insertionUser || {}
  const districtName = get('name')(district)
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}`.trim() : null

  return districtId && insertionData ? (
    <Toolbar variant="insertion">
      <Typography variant="paragraph">
        <span className="district-label">District Created Content:</span>{' '}
        {districtName}
        {fullName && !userIsStudent ? `, by ${fullName}` : ''}
      </Typography>
    </Toolbar>
  ) : null
}

export default InsertionContentBanner
