import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material/styles'
import Radio from 'common/formControls/switches/Radio'
import Html from 'common/text/Html'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { isEchoFeature } from 'shared/consts'
import AnswerChoiceBox from '../MultipleChoice/AnswerChoiceBox'

const SingleSelect = ({ selectedValueState, setSelectedValue }) => {
  const {
    completed,
    interactive: { contentSubType },
    interactiveData: { colsPerRow, showSelector, options, prompt },
    isGrading,
    onInteract,
  } = useContext(interactiveContext)

  const { palette, shadows } = useTheme()
  const isEcho = isEchoFeature(contentSubType)

  const handleChange = useCallback(
    ({ target }) => {
      const selectedValue = `${target.value}`
      setSelectedValue(selectedValue)
      onInteract({ selectedValue })
    },
    [onInteract, setSelectedValue],
  )

  const selectedBoxShadow = {
    boxShadow: shadows.border,
    color: palette.boxshadowPrimary,
  }

  return (
    <RadioGroup
      aria-label={prompt}
      disabled={completed || isGrading}
      display="flex"
      onChange={handleChange}
      style={{ flexDirection: 'row' }}
      value={selectedValueState}>
      {options?.map(({ id, label }) => (
        <AnswerChoiceBox
          barColor="transparent"
          bgcolor="background.paper"
          borderLeft="8px solid"
          className="AnswerChoiceBox"
          key={id}
          mb={1.5}
          pl={1}
          sx={{
            marginRight: '1%',
            width: `${100 / (colsPerRow < 1 ? 1 : colsPerRow) - 1}%`,
            ...(selectedValueState === `${id}` ? selectedBoxShadow : {}),
          }}>
          <Radio
            disabled={completed || isGrading}
            key={id}
            label={
              <Html
                body={label}
                substituteInlineBlocks
              />
            }
            style={{
              color: palette.text.primary,
            }}
            value={id}
            variant={showSelector || isEcho ? '' : 'hide-control'}
          />
        </AnswerChoiceBox>
      ))}
    </RadioGroup>
  )
}

SingleSelect.propTypes = {
  selectedValueState: PropTypes.string,
  setSelectedValue: PropTypes.func,
}

export default SingleSelect
