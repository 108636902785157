import { Box } from '@mui/material'
import Centered from 'common/layout/Centered'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Well from 'common/layout/Well'
import { INTERACTIVE_TYPE_CHART_COMPLETION } from 'core/consts'
import { find, shuffle } from 'fp/arrays'
import { isDefined, matches, not, when } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import withQuestionPrompt from '../../Interactive/withQuestionPrompt'
import DraggableOptions from './DragAndDrop/DraggableOptions'
import OptionsDialog from './OptionsDialog'
import Table from './Table'
import { actions, reducer } from './utils'

const {
  CHART_COMPLETION_VOCAB_DEFINITIONS,
  CHART_COMPLETION_VOCAB_SENTENCES,
  NORMAL,
} = interactiveVariants

const ChartCompletion = withQuestionPrompt(() => {
  const {
    completed,
    interactionData: { items: response = [] },
    interactiveData: {
      columns,
      displayBelow,
      items,
      matchingLayout,
      rows,
      variant = NORMAL,
    },
    isGrading,
    onInteract,
    setSubmitEnabled,
    submittable,
  } = useContext(interactiveContext)

  /** NOTE:
   * For a static item, a response for item {id: 'GEN-xxxx'} looks like { id: 'GEN-xxxx', value: 'input text' }
   * for a drag-n-drop item, the response is defined as the dropped item and setting its row and column IDs
   * { id:'GEN-xxxx', row:'GEN-yyyy', column:'GEN-zzzz' }
   *
   * (Real IDs will be longer than the IDs in the above examples.)
   *
   */

  const [state, dispatch] = useReducer(reducer, {
    items: response,
    droppedItems: new Set(),
  })
  const [selectedItem, setSelectedItem] = useState()
  const [changesMade, setChangesMade] = useState(false)
  const [responseReturned, setResponseReturned] = useState(false)
  const totalOptions = useMemo(
    () => shuffle(items.filter(({ isStatic }) => not(isStatic))),
    [items],
  )

  const availableOptions = useMemo(
    () => totalOptions.filter(({ id }) => not(state.droppedItems.has(id))),
    [totalOptions, state.droppedItems],
  )

  const isSmallScreen = useContainerQuery().down('md')

  const isVocabVariant = [
    CHART_COMPLETION_VOCAB_DEFINITIONS,
    CHART_COMPLETION_VOCAB_SENTENCES,
  ].includes(variant)
  const localRows = useMemo(
    () => (!isGrading && isVocabVariant ? shuffle(rows) : rows),
    [isGrading, isVocabVariant, rows],
  )
  const localDispatch = useCallback(action => {
    setChangesMade(true)
    dispatch(action)
  }, [])

  useEffect(() => {
    if (response?.length && !responseReturned) {
      setResponseReturned(true)

      // Filter non-static items that have been placed (have row and column)
      const droppedItemIds = response
        .filter(item => item.row && item.column)
        .map(item => item.id)

      dispatch({
        type: actions.SETUP,
        items: response,
        droppedItems: droppedItemIds,
      })
    }
  }, [responseReturned, response])

  useEffect(() => {
    setSubmitEnabled(
      !!state.items.filter(({ column, row, value }) => value || (column && row))
        .length,
    )
  }, [setSubmitEnabled, state.items])

  const SelectableOptions = useCallback(
    () =>
      Boolean(!(completed || isSmallScreen)) &&
      totalOptions.length > 0 && (
        <DraggableOptions
          availableOptions={availableOptions}
          dispatch={localDispatch}
        />
      ),
    [availableOptions, completed, isSmallScreen, localDispatch, totalOptions],
  )

  useDeepCompareEffect(() => {
    when(changesMade && !isGrading, onInteract, { items: state.items })
  }, [changesMade, onInteract, state])

  return (
    <>
      {!displayBelow && <SelectableOptions />}

      {isVocabVariant && !rows.length ? (
        <Well>
          <Centered>(No vocabulary terms available)</Centered>
        </Well>
      ) : (
        <Table
          {...{
            columns,
            dispatch: localDispatch,
            items,
            matchingLayout,
            rows: localRows,
            onSelectItem: setSelectedItem,
            readOnly: isGrading || !submittable,
            response: state.items,
          }}
        />
      )}

      {Boolean(!completed && isDefined(selectedItem)) && (
        <Centered>
          <OptionsDialog
            dispatch={localDispatch}
            onClose={() => {
              setSelectedItem(null)
            }}
            open
            response={state.items}
            selectedColumn={
              find(matches('id', selectedItem.column))(columns)?.header
            }
            selectedItem={selectedItem}
            totalOptions={totalOptions}
          />
        </Centered>
      )}

      {Boolean(displayBelow) && (
        <Box mt={4}>
          <SelectableOptions />
        </Box>
      )}
    </>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_CHART_COMPLETION,
}

export default ChartCompletion
