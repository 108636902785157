import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { useContext } from 'react'
import { INTERACTIVE_TYPE_FILL_IN_THE_BLANK_AND_CLOZE } from 'shared/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const FillInTheBlankAndCloze = withQuestionPrompt(() => {
  const {
    interactiveData: { sentences },
  } = useContext(interactiveContext)

  return (
    <div>
      {sentences.map(sentence => (
        <div key={sentence.id}>
          {sentence.fragments.map(fragment => (
            <div key={fragment.id}>
              Type: {fragment.type} <br />
              Content: {fragment.content || 'N/A'} <br />
              Option Set Id: {fragment.optionSet || 'N/A'} <br />
              Answer: {fragment.correctAnswer || 'N/A'} <br />
              <hr />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_FILL_IN_THE_BLANK_AND_CLOZE,
}

export default FillInTheBlankAndCloze
