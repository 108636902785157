import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material/styles'
import Radio from 'common/formControls/switches/Radio'
import Html from 'common/text/Html'
import { numberOrString } from 'core/shapes'
import { first } from 'fp/arrays'
import { omit } from 'fp/objects'
import { isDefined } from 'fp/utils'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { getCorrectAnswerChoiceIds } from 'selectors/multipleChoice'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import AnswerChoiceBox from './AnswerChoiceBox'
import BaseSelect from './BaseSelect'

const ItemRenderer = ({
  isCorrect = false,
  explanation = '',
  isSelected = false,
  isSelectable = false,
  label = '',
  ...rest
}) => {
  const {
    completed,
    scoreData: { hidden: isScoreHidden } = {},
    isPastSubmissionDate,
  } = useContext(interactiveContext)

  const showAnswers =
    useIsInAnswerKeyContext() ||
    ((completed || isPastSubmissionDate) && !isScoreHidden)

  const { palette, shadows } = useTheme()
  const noBoxShadow = { boxShadow: 'none' }

  const getStyle = () => {
    let bgColor = 'transparent'
    if (isSelected) {
      if (isCorrect) {
        bgColor = 'success.main'
      } else {
        bgColor = 'error.main'
      }
    }

    const boxShadow =
      showAnswers || !isSelectable
        ? { ...noBoxShadow, pointerEvents: 'none' }
        : isSelected
          ? {
              boxShadow: shadows.border,
              color: palette.boxshadowPrimary,
            }
          : {}

    return {
      background: bgColor,
      ...boxShadow,
    }
  }

  return (
    <AnswerChoiceBox style={getStyle()}>
      <Radio
        label={
          <Box
            color="grey.0"
            component="span">
            <Html
              body={label}
              substituteInlineBlocks
            />
          </Box>
        }
        disabled={!isSelectable}
        {...omit(['id'])(rest)}
      />
    </AnswerChoiceBox>
  )
}

ItemRenderer.propTypes = {
  explanation: PropTypes.string,
  label: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool,
}

const RadioGroupRenderer = ({
  handleChange,
  options,
  previewing,
  selectedAnswerId,
  ...rest
}) => {
  const onChange = useCallback(
    ({ target: { value } }) => {
      handleChange(value)
    },
    [handleChange],
  )

  return (
    <RadioGroup
      name="selectedAnswer"
      onChange={onChange}
      required={!previewing}
      value={selectedAnswerId}
      sx={{ flexDirection: 'row' }}
      {...rest}>
      {options.map(option => {
        const { id } = option
        return createElement(ItemRenderer, {
          ...option,
          key: id,
          isSelected: id === selectedAnswerId,
          value: id,
        })
      })}
    </RadioGroup>
  )
}

RadioGroupRenderer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  previewing: PropTypes.bool.isRequired,
  selectedAnswerId: numberOrString,
}

const SingleSelect = () => {
  const {
    // API grading expects an array for all multiple choice interactives,
    // regardless of how many correct answers there are.
    interactionData: { selectedAnswerChoiceIds },
    interactiveData,
    onInteract,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const { previewing = false } = interactiveData
  const [selectedAnswerIdState, setSelectedAnswerIdState] = useState()

  const selectedAnswerChoiceId = first(
    useIsInAnswerKeyContext()
      ? getCorrectAnswerChoiceIds(interactiveData)
      : selectedAnswerChoiceIds,
  )

  useLayoutEffect(() => {
    setSelectedAnswerIdState(selectedAnswerChoiceId)
  }, [selectedAnswerChoiceId])

  const handleChange = useCallback(
    newAnswerIdState => {
      setSelectedAnswerIdState(newAnswerIdState)
      onInteract({
        selectedAnswerChoiceIds: [newAnswerIdState],
      })
    },
    [onInteract],
  )

  useEffect(() => {
    setSubmitEnabled(isDefined(selectedAnswerIdState))
  }, [selectedAnswerIdState, setSubmitEnabled])

  const GroupRenderer = useMemo(
    () =>
      withProps(RadioGroupRenderer, {
        handleChange,
        previewing,
        selectedAnswerId: selectedAnswerIdState,
      }),
    [handleChange, previewing, selectedAnswerIdState],
  )

  return <BaseSelect GroupRenderer={GroupRenderer} />
}

export default SingleSelect
