import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { contentShape } from 'core/shapes'
import EnhancedContentHtml from 'hss/ContentViewer/Chapter/Block/EnhancedContentHtml'
import StyledContent from 'hss/ContentViewer/StyledContent'

const ScaffoldRenderer = props => {
  const { scaffold } = props

  /* istanbul ignore next */
  const body = scaffold.data.body || ''

  return (
    <StyledContent>
      <EnhancedContentHtml
        body={body}
        childrenMetadata={[]}
        className="attached-scaffold"
        data-contentid={scaffold.id}
        data-contenttype={CONTENT_TYPE_SCAFFOLD}
      />
    </StyledContent>
  )
}

ScaffoldRenderer.propTypes = {
  scaffold: contentShape.isRequired,
}

export default ScaffoldRenderer
