import { FormGroup } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Checkbox from 'common/formControls/switches/Checkbox'
import Html from 'common/text/Html'
import { get, set } from 'fp/objects'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { compose } from 'redux'
import AnswerChoiceBox from '../MultipleChoice/AnswerChoiceBox'
import {
  arrayToObjectKeys,
  onlyTruthyKeys,
} from '../MultipleChoice/MultiSelect'

const MultiSelect = ({ selectedValueState, setSelectedValue }) => {
  const {
    completed,
    interactiveData: { colsPerRow, showSelector, options, prompt },
    isGrading,
    onInteract,
  } = useContext(interactiveContext)

  const [answerChoiceStates, setAnswerChoiceStates] = useState({})

  const { palette, shadows } = useTheme()

  useEffect(() => {
    setAnswerChoiceStates(arrayToObjectKeys(selectedValueState))
  }, [selectedValueState])

  const handleChange = useCallback(
    ({ target: { checked } }, id) => {
      const newAnswerChoiceStates = set(id, checked)(answerChoiceStates)
      setAnswerChoiceStates(newAnswerChoiceStates)
      const selectedValue = onlyTruthyKeys(newAnswerChoiceStates)
      setSelectedValue(selectedValue)
      onInteract({ selectedValue })
    },
    [answerChoiceStates, onInteract, setSelectedValue],
  )

  const selectedBoxShadow = {
    boxShadow: shadows.border,
    color: palette.boxshadowPrimary,
  }

  return (
    <FormGroup
      aria-label={prompt}
      disabled={completed || isGrading}
      display="flex"
      style={{ flexDirection: 'row' }}>
      {options?.map(({ id, label }) => {
        const checked = compose(Boolean, get(String(id)))(answerChoiceStates)
        return (
          <AnswerChoiceBox
            barColor="transparent"
            bgcolor="background.paper"
            borderLeft="8px solid"
            className="AnswerChoiceBox"
            key={id}
            mb={1.5}
            pl={1}
            sx={{
              marginRight: '1%',
              width: `${100 / (colsPerRow < 1 ? 1 : colsPerRow) - 1}%`,
              ...(checked ? selectedBoxShadow : {}),
            }}>
            <Checkbox
              checked={checked}
              disabled={completed || isGrading}
              key={id}
              label={
                <Html
                  body={label}
                  substituteInlineBlocks
                />
              }
              onChange={e => handleChange(e, id)}
              style={{
                color: palette.text.primary,
              }}
              variant={showSelector ? '' : 'hide-control'}
            />
          </AnswerChoiceBox>
        )
      })}
    </FormGroup>
  )
}

MultiSelect.propTypes = {
  selectedValueState: PropTypes.arrayOf(PropTypes.string),
  setSelectedValue: PropTypes.func,
}

export default MultiSelect
