import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { get } from 'fp/objects'
import { unwrap } from 'fp/strings'
import { matches } from 'fp/utils'
import useCurrentUser from 'hooks/useCurrentUser'
import useNavigation from 'hooks/useNavigation'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import DrawerButtons from 'hss/sections/contentBlocks/Interactive/Scaffolds/DrawerButtons'
import {
  baseSx,
  closedMixin,
  openedMixin,
} from 'hss/sections/contentBlocks/Interactive/Scaffolds/DrawerWrapper'
import ScaffoldRenderer from 'hss/sections/contentBlocks/Interactive/Scaffolds/ScaffoldRenderer'
// TODO: Stop ignoring this file postMVP
/* istanbul ignore file */
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { getCurrentAssignmentSubmitted } from 'selectors/userAssignments'
import { isStudent } from 'selectors/users'
import { importantRem, px, rem } from 'styling/theming/base/mixins'

const styleProp = 'width'

const scaffoldSupportedFeatures = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
]

const EchoScaffolds = () => {
  const theme = useTheme()

  const { blockBundles } = useContext(contentViewerContext)
  const { isEchoScaffoldOpen, setIsEchoScaffoldOpen } =
    useContext(sidebarContext)
  const [currentDrawerIndex, setCurrentDrawerIndex] = useState(0)
  const {
    user: { proficiencyId },
  } = useCurrentUser()
  const notStudent = !useSelector(isStudent)
  const isAssignmentSubmitted = useSelector(getCurrentAssignmentSubmitted)

  const attachedScaffolds = blockBundles
    .map(get('block'))
    .filter(matches('contentType', CONTENT_TYPE_SCAFFOLD))
    .filter(
      ({ proficiencyIds }) =>
        notStudent || proficiencyIds?.includes(proficiencyId),
    )

  const {
    location: { hash },
  } = useNavigation()

  const scaffold = attachedScaffolds[currentDrawerIndex]

  return !isAssignmentSubmitted &&
    !!scaffold &&
    scaffoldSupportedFeatures.includes(unwrap('#')(hash)) ? (
    <>
      <Drawer
        anchor="right"
        open={isEchoScaffoldOpen}
        sx={{
          ...baseSx,
          ...(isEchoScaffoldOpen
            ? openedMixin(theme, styleProp, '100%')
            : closedMixin(theme, styleProp, 0)),

          '.MuiDrawer-paper': {
            borderLeftStyle: 'solid',
            borderWidth: 7,
            borderColor: 'cobalt.0',
            zIndex: theme.zIndex.drawer + 1,
            ...(isEchoScaffoldOpen
              ? openedMixin(theme, styleProp, '100%')
              : closedMixin(theme, styleProp, 0)),
          },
          '.MuiPaper-root': {
            paddingLeft: isEchoScaffoldOpen ? rem(6) : 0,
          },
          '.MuiPaper-root .MuiContainer-root:first-of-type.MuiContainer-root:first-of-type':
            {
              marginTop: importantRem(4),
              paddingRight: importantRem(2),
              paddingLeft: importantRem(2),
              '.block-full-width': {
                padding: rem(2, 0),
                borderStyle: 'none',
              },
            },
        }}
        variant="permanent">
        <ScaffoldRenderer scaffold={scaffold} />
      </Drawer>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          zIndex: theme.zIndex.drawer + 1,
          display: 'flex',
          ...(isEchoScaffoldOpen
            ? {
                justifyContent: 'flex-start',
                marginLeft: px(5),
              }
            : {
                marginLeft: 'calc(100% - 36px)',
              }),
        }}>
        <DrawerButtons
          {...{
            attachedScaffolds,
            currentDrawerIndex,
            direction: 'vertical',
            open: isEchoScaffoldOpen,
            reverseChevronDirection: true,
            setCurrentDrawerIndex,
            setOpen: setIsEchoScaffoldOpen,
          }}
        />
      </Box>
    </>
  ) : null
}

export default EchoScaffolds
