import { styled } from '@mui/material/styles'
import ReactImageAnnotation from '@studysync/react-image-annotation'
import BaseImage from 'common/indicators/Image'
import useToggleState from 'hooks/useToggleState'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext, useEffect } from 'react'
import Expander from '../../Image/Expander'

const Container = styled('div', { name: 'ChartItem-ImageRenderer' })({
  img: { cursor: 'pointer', maxWidth: '100%' },
})

interface ImageRendererProps
  extends React.ComponentPropsWithoutRef<typeof Container> {
  imageDescription: string
  url: string
}

const ImageRenderer = ({ url, imageDescription }: ImageRendererProps) => {
  const { boosted, setBoosted } = useContext(interactiveContext)

  const [expanded, setExpanded] = useToggleState()

  const handleClick = () => {
    setExpanded()
  }
  useEffect(() => {
    //if it's already boosted within the same interactive, don't reset it
    setBoosted(boosted ? boosted : expanded)
  }, [boosted, expanded, setBoosted])

  const renderOverlay = () => null // if you pass in nothing, the default overlay gets rendered

  return (
    <Container aria-label={imageDescription}>
      <BaseImage
        alt={imageDescription}
        onClick={handleClick}
        src={url}
      />
      {Boolean(expanded) && (
        <Expander
          expanded
          onClick={handleClick}>
          <ReactImageAnnotation
            allowTouch
            annotations={[]}
            disableAnnotation
            disableZoom={false}
            imageProps={{ alt: imageDescription }}
            renderOverlay={renderOverlay}
            src={url}
            value={{}}
          />
        </Expander>
      )}
    </Container>
  )
}

export default ImageRenderer
