import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import MenuButton from 'common/menus/MenuButton'
import {
  ABILITY_CONTENT_RESTRICTION,
  CONTENT_RESTRICTION_TYPE_CENSORED,
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  CONTENT_RESTRICTION_TYPE_NONE,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import useAbilityChecker from 'hooks/useAbilityChecker'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { labels as contentTypeLabels } from 'hss/ContentBuilder/consts'
import EyeDisabled from 'hss/images/indicators/eye-disabled.svg'
import { margin } from 'polished'
import { memo, useCallback } from 'react'
import { AlertOctagon, Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import type { Content, ContentRestrictionType } from 'reducers/@types/content'
import actionTypes from 'reducers/actionTypes'
import { canRemoveInsertedContent } from 'selectors/content'
import ContentInsertionRemoval from './ContentInsertionRemoval'
import ContentInsertionSectionRemovalMenuItem from './ContentInsertionSectionRemovalMenuItem'

const fallback: ContentRestrictionType = CONTENT_RESTRICTION_TYPE_NONE

type CONTENT_RESTRICTION_TYPE_MIXED_CHILDREN = 'mixed-children'

type MixedRestrictionType =
  | ContentRestrictionType
  | CONTENT_RESTRICTION_TYPE_MIXED_CHILDREN

const labels: Record<
  MixedRestrictionType,
  {
    label: string
    description: string
  }
> = {
  [CONTENT_RESTRICTION_TYPE_CENSORED]: {
    label: 'Disabled for teachers and students',
    description: 'This content is not available to teachers or students.',
  },
  [CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]: {
    label: 'Enabled for teachers, hidden for students',
    description:
      'This content is hidden by default and must be enabled for students.',
  },
  [CONTENT_RESTRICTION_TYPE_NONE]: {
    label: 'Enabled for teachers and students',
    description: 'This content is available to teachers and students.',
  },
  'mixed-children': {
    label: 'Some subsections are hidden',
    description: 'Some subsections are hidden.',
  },
}

const Icons: Record<MixedRestrictionType, React.ReactElement> = {
  [CONTENT_RESTRICTION_TYPE_CENSORED]: <EyeDisabled />,
  [CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]: <EyeOff />,
  [CONTENT_RESTRICTION_TYPE_NONE]: <Eye />,
  'mixed-children': <AlertOctagon />,
}

const AllOrNothing = ({
  checked: initiallyChecked,
  content,
  onChange,
}: {
  checked: boolean
  content: Content
  onChange: (type: string) => void
}) => {
  const [checked, setChecked] = useStateWithDynamicDefault(initiallyChecked)

  return (
    <SwitchFormControl
      checked={checked}
      color="secondary"
      label={`Enable ${(contentTypeLabels as Record<string, string>)[content.contentType]}`}
      onChange={() => {
        setChecked(!checked)
        onChange(
          checked
            ? CONTENT_RESTRICTION_TYPE_CENSORED
            : CONTENT_RESTRICTION_TYPE_NONE,
        )
      }}
      style={{ width: 'unset', marginTop: 0 }}
    />
  )
}

type SelectiveRestrictionProps = {
  content: Content
  onChange: (type: string) => void
  type: ContentRestrictionType
}

const SelectiveRestriction = memo(
  ({ content, onChange, type: initialType }: SelectiveRestrictionProps) => {
    const [type, setType] = useStateWithDynamicDefault(initialType)

    const iconButtonMode = [
      CONTENT_TYPE_SECTION,
      CONTENT_TYPE_SUBSECTION,
      CONTENT_TYPE_ECHO,
    ].includes(content.contentType)

    const isEcho = content.contentType === CONTENT_TYPE_ECHO

    const handleClick = ({ target }: React.SyntheticEvent) => {
      const { value } = (target as HTMLElement).attributes.getNamedItem(
        'data-value',
      ) as {
        value: ContentRestrictionType
      }
      setType(value)
      onChange(value)
    }

    return iconButtonMode ? (
      <MenuButton
        color={isEcho ? 'primary' : 'secondary'}
        data-bodyvariant={isEcho ? 'body2' : 'body1'}
        icon={Icons[type]}
        label={labels[type].description}
        menuProps={{}}
        sx={{ mr: 1 }}>
        <Box sx={{ 'li svg': { marginRight: margin(8) } }}>
          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_NONE}
            onClick={handleClick}>
            {Icons[CONTENT_RESTRICTION_TYPE_NONE]}
            {labels[CONTENT_RESTRICTION_TYPE_NONE].label}
          </MenuItem>

          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT}
            onClick={handleClick}>
            {Icons[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]}
            {labels[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT].label}
          </MenuItem>

          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_CENSORED}
            onClick={handleClick}>
            {Icons[CONTENT_RESTRICTION_TYPE_CENSORED]}
            {labels[CONTENT_RESTRICTION_TYPE_CENSORED].label}
          </MenuItem>

          <ContentInsertionSectionRemovalMenuItem content={content} />
        </Box>
      </MenuButton>
    ) : (
      <Select
        // aria-description={labels[type].description}
        aria-label="content restrictions"
        onChange={({ target }) => {
          setType(target.value as ContentRestrictionType)
          onChange(target.value)
        }}
        style={{ minWidth: 170 }}
        value={type}>
        <MenuItem value={CONTENT_RESTRICTION_TYPE_NONE}>
          {labels[CONTENT_RESTRICTION_TYPE_NONE].label}
        </MenuItem>
        <MenuItem value={CONTENT_RESTRICTION_TYPE_CENSORED}>
          {labels[CONTENT_RESTRICTION_TYPE_CENSORED].label}
        </MenuItem>
        <MenuItem value={CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT}>
          {labels[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT].label}
        </MenuItem>
      </Select>
    )
  },
)

const ContentRestrictionControls = ({ content }: { content: Content }) => {
  const { contentType, id: contentId } = content
  const type: MixedRestrictionType =
    content?.contentRestriction?.type || fallback

  const allOrNothingMode = [CONTENT_TYPE_COURSE, CONTENT_TYPE_CHAPTER].includes(
    contentType,
  )

  const dispatch = useDispatch()

  const has = useAbilityChecker()
  const canRestrict = has(ABILITY_CONTENT_RESTRICTION)
  const isContentInsertedAndRemovable = useSelector(
    canRemoveInsertedContent(content),
  )

  const handleChange = useCallback(
    (newType: string) => {
      const { id } = content.contentRestriction || {}

      dispatch({
        type: actionTypes.CONTENT_RESTRICTION_ALTERATION,
        payload: {
          contentId,
          contentRestrictionId: id,
          contentType,
          type: newType,
        },
      })
    },
    [content.contentRestriction, contentId, contentType, dispatch],
  )

  if (canRestrict && allOrNothingMode) {
    return (
      <AllOrNothing
        checked={type !== CONTENT_RESTRICTION_TYPE_CENSORED}
        content={content}
        onChange={handleChange}
      />
    )
  }

  if (isContentInsertedAndRemovable) {
    return <ContentInsertionRemoval content={content} />
  }

  if (canRestrict) {
    return (
      <SelectiveRestriction
        content={content}
        onChange={handleChange}
        type={type}
      />
    )
  }

  return null
}

export default ContentRestrictionControls

export { labels, fallback, Icons }
