import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'

type CustomizeProps = {
  customizeAssignment: boolean
  toggleCustomizeAssignment: () => void
}

const Customize = ({
  customizeAssignment,
  toggleCustomizeAssignment,
}: CustomizeProps) => (
  <SwitchFormControl
    checked={customizeAssignment}
    label="Customize Assignment"
    onChange={toggleCustomizeAssignment}
  />
)

export default Customize
