import CollapsibleSection from 'common/layout/CollapsibleSection'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import type { ReactNode } from 'react'

interface VennSectionProps {
  children: ReactNode
  title: string
  initiallyExpanded?: boolean
}

const VennCollapsible = ({
  children,
  title,
  initiallyExpanded = true,
}: VennSectionProps) => (
  <CollapsibleSection
    initiallyExpanded={initiallyExpanded}
    label={
      <Headline
        textAlign="center"
        title={title}
        variant="feature-paragraph-semibold"
      />
    }
    variant="interactive-grading">
    <HeadlineLevelOffset>{children}</HeadlineLevelOffset>
  </CollapsibleSection>
)

export default VennCollapsible
