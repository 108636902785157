import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import { Maximize2, Minimize2 } from 'react-feather'

const Expander = ({ expanded, onChange }) => (
  <IconButton
    data-bodyvariant="body1"
    data-expansionbutton="true"
    onClick={onChange}
    style={{
      width: 48,
      height: 48,
    }}>
    <Icon
      style={{
        width: 'auto',
        height: 'auto',
        lineHeight: 0,
      }}>
      {expanded ? <Minimize2 /> : <Maximize2 />}
    </Icon>
  </IconButton>
)

Expander.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Expander
