import { Box } from '@mui/material'
// import HFRichTextEdit2 from 'common/formControls/textInputs/HFRichTextEdit2'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import DebugViewInternalContent from 'common/formControls/textInputs/RichTextEdit/utils/DebugViewInternalContent'
import useComponentSize from 'hooks/useComponentSize'
import { contentAuthorInteractives } from 'hss/ContentBuilder/consts'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isDevEnv, isPreviewEnv } from 'selectors/index'
import { px } from 'styling/theming/base/mixins'
import { useAuthorContext } from '../AuthorProvider'

/**
 * Ideally these would be calculated directly by inspecting the actual toolbar
 * height, however there doesn't seem to be a way to keep dom node references
 * when passed through a lazy-loaded component.
 *
 * These then are hardcoded widths for when the toolbar or action bar wraps.
 * Update the former if we add more buttons to the toolbar!
 */
const magicToolbarWidthNumber = 710
const magicButtonWidthNumber = 350

export const features = {
  'headlines.dual': true,
  'images.block': true,
  'images.inline': true,
  interactives: contentAuthorInteractives,
  'links.advanced': false,
  'lists.card': true,
  'lists.sourceHeader': false,
  'lists.unstyled': false,
  tables: false,
  'typography.answer': false,
  'typography.advancedHeader': false,
  'typography.contentHeader': true,
  'typography.credit': false,
  'typography.dropCap': false,
  'typography.featuredParagraph': true,
  'typography.format': true,
  'typography.highlight': false,
  'typography.numberCrunch': false,
  'typography.sourceTitle': false,
  'typography.subHeading': false,

  'typography.definition': false,
  'typography.header-one': false,
  'typography.header-two': false,
  'typography.header-three': false,
  'typography.blockquote': true,
  'typography.blockquote-display': false,
}

const ContentPanel = () => {
  const { debugging } = useAuthorContext()
  const isDev = useSelector(isDevEnv)
  const isStaging = useSelector(isPreviewEnv)

  const ref = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const { width: editorWidth } = useComponentSize(ref)

  const [editorHeight, setEditorHeight] = useState('0')
  const [maxHeight, setMaxHeight] = useState('100vh')

  const { top: toolbarTop } = ref.current?.getBoundingClientRect?.() ?? {
    top: 0,
  }
  const { height: infoHeight } =
    infoRef.current?.getBoundingClientRect?.() ?? {}

  useEffect(() => {
    setMaxHeight(debugging ? '52vh' : '1000px')

    let newHeight =
      toolbarTop +
      82 +
      (editorWidth > magicToolbarWidthNumber ? -21 : 33) +
      (infoHeight ?? 0)

    if (editorWidth < magicButtonWidthNumber) {
      newHeight += 100
      setMaxHeight(debugging ? '23vh' : '1000px')
    }

    if (editorWidth > magicToolbarWidthNumber) {
      setMaxHeight(debugging ? '60vh' : '1000px')
    }

    setEditorHeight(`calc(100vh - ${newHeight}px)`)
  }, [editorWidth, debugging, toolbarTop, infoHeight])

  const containerProps = useMemo(
    () => ({
      sx: {
        '.DraftEditor-editorContainer, .public-DraftEditor-content': {
          height: '100%',
        },
        '.DraftEditor-root': {
          height: editorHeight,
        },
        mt: px(1),
        mr: 1,
        mb: 1,
        ml: px(2),
      },
    }),
    [editorHeight],
  )

  return (
    <Box
      ref={ref}
      {...containerProps}>
      <HFRichTextEdit
        name="body"
        label=""
        features={features}
        maxHeight={maxHeight}
        // validate={({required, value})}
      />

      {Boolean((isDev || isStaging) && debugging) && (
        <Box mr={1}>
          <DebugViewInternalContent
            fieldsToWatch={[{ name: 'body' }]}
            formField={'body'}
          />
        </Box>
      )}
    </Box>
  )
}

export default ContentPanel
