import { styled } from '@mui/material'
import { uploadShape } from 'core/shapes'
import { get } from 'fp/objects'
import Figure from 'hss/sections/contentBlocks/interactives/Figure'
import LongTextAlternative from 'hss/sections/contentBlocks/interactives/Image/LongTextAlternative'
import { useContext, useId } from 'react'
import {
  containedPaddingLeft,
  containedPaddingRight,
} from 'styling/theming/base'
import { contentViewerContext } from '../../ContentViewerProvider'

const HeroImage = styled('div', { name: 'Subsection-Hero' })(() => ({
  overflow: 'hidden',
  position: 'relative',
  img: {
    display: 'block',
    maxWidth: '150rem',
    margin: '0 auto',
    objectFit: 'cover',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '50%',
    background: 'var(--bg-image)',
    backgroundSize: 'cover',
    filter: 'blur(5px) brightness(50%)',
    transform: 'scale(1.1)',
  },
  '&::before': {
    left: 0,
    backgroundPosition: 'left center',
  },
  '&::after': {
    right: 0,
    backgroundPosition: 'right center',
  },
}))

const Hero = () => {
  const {
    subsection,
    subsection: {
      data: { caption, captionPosition, imageAltText, longTextAlternative },
    },
  } = useContext(contentViewerContext)

  const captionId = useId()
  const descriptionId = useId()
  const upload = get('uploadsMap.image')(subsection)

  return upload ? (
    <>
      <Figure
        caption={caption}
        captionId={captionId}
        captionPosition={captionPosition}
        hideCaption={!caption}
        useContainedPadding>
        <HeroImage style={{ '--bg-image': `url(${upload.url})` }}>
          <img
            alt={imageAltText}
            aria-describedby={captionId}
            aria-details={longTextAlternative ? descriptionId : null}
            src={upload.url}
          />
        </HeroImage>
      </Figure>
      {Boolean(longTextAlternative) && (
        <LongTextAlternative
          html={longTextAlternative}
          id={descriptionId}
          label="Image Details"
          style={{
            marginLeft: containedPaddingLeft,
            marginRight: containedPaddingRight,
          }}
        />
      )}
    </>
  ) : null
}

Hero.propTypes = {
  upload: uploadShape,
}

export default Hero
