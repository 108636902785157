import { Box, List, ListItem, Typography } from '@mui/material'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import Headline from 'common/text/Headline'
import { contentShape } from 'core/shapes'
import { first, last, map, orderBy, reverse } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { compose } from 'redux'
import { CONTENT_TYPE_RUBRIC_CRITERIA } from 'shared/consts'

const RubricDisplay = props => {
  const { rubric } = props
  const { children } = rubric

  const rubricCriteria = children.filter(
    rubric => rubric.contentType === CONTENT_TYPE_RUBRIC_CRITERIA,
  )

  return (
    <Box sx={{ m: 4, 'ul.rubric': { ml: 0 }, li: { px: 0, pb: 1, pt: 0 } }}>
      <Headline title="Rubric">
        {rubricCriteria.map(({ data: { title, options } }, idx) => {
          return (
            <CollapsibleSection
              initiallyExpanded={idx === 0}
              color="primary"
              variant="rubric-display"
              label={
                <span>
                  <Typography variant="paragraph-semibold">
                    (Score {get('score')(first(options))} -{' '}
                    {get('score')(last(options))})
                  </Typography>
                  {` ${title}`}
                </span>
              }
              key={idx}>
              <List className="rubric">
                {compose(
                  map(({ id, description, score }) => (
                    <ListItem key={id}>
                      <Typography
                        variant="paragraph-semibold"
                        component="span">
                        Score {score}
                      </Typography>
                      : {description}
                    </ListItem>
                  )),
                  reverse,
                  orderBy('score'),
                  fallbackTo([]),
                )(options)}
              </List>
            </CollapsibleSection>
          )
        })}
      </Headline>
    </Box>
  )
}

RubricDisplay.propTypes = {
  rubric: contentShape.isRequired,
}

export default RubricDisplay
