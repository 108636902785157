import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { map } from 'fp/arrays'
import { get } from 'fp/objects'
import { matches } from 'fp/utils'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getLocalSetting } from 'selectors/localSettings'
import { interactiveContext } from './InteractiveProvider'

const RubricFeedback = () => {
  const {
    completed,
    interactive: { rubric },
    scoreData,
  } = useContext(interactiveContext)
  const { comment, requiresGrading, rubricSelections } = scoreData || {}

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  if (presenterModeEnabled) return null

  return (
    <>
      {Boolean(
        completed && !requiresGrading && (rubricSelections?.length || comment),
      ) && (
        <Box mt={6}>
          {Boolean(rubricSelections?.length && rubric?.children?.length) &&
            rubric.children.map(
              ({ id, data: { title, options } }, rowIndex) => {
                const { description, score } = options.find(
                  matches('id', rubricSelections[rowIndex]),
                )
                const maxScore = compose(
                  scores => Math.max(...scores),
                  map(get('score')),
                )(options)

                return (
                  <p key={id}>
                    <Typography variant="body1-semibold">
                      {title}: {score}/{maxScore}
                    </Typography>
                    <br />
                    {description}
                  </p>
                )
              },
            )}

          {Boolean(comment) && (
            <p>
              <Typography variant="body1-semibold">
                Teacher Comments:{' '}
              </Typography>
              {comment}
            </p>
          )}
        </Box>
      )}
    </>
  )
}

export default RubricFeedback
