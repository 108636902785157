import AddIcon from '@mui/icons-material/AddRounded'
import Button from '@mui/material/Button'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useToggleState from 'hooks/useToggleState'
import CreationDialog from 'hss/ContentAuthor/Dashboard/CreationDialog'
import {
  ABILITY_SELF_CONTENT_AUTHORING,
  FEATURE_FLAG_CUSTOMER_CONTENT,
} from 'shared/consts'

const AuthorContent: React.FC = () => {
  const [open, toggleOpen] = useToggleState(false)

  const isContentAuthoringLive = useAbilityCheck(FEATURE_FLAG_CUSTOMER_CONTENT)
  if (!isContentAuthoringLive) return null

  return (
    <>
      <Button
        color="secondary"
        onClick={toggleOpen}
        startIcon={<AddIcon />}
        variant="primary"
        data-testid="add-to-library-btn">
        Add to Library
      </Button>

      <CreationDialog
        onClose={toggleOpen}
        open={open}
      />
    </>
  )
}

const Wired = withAbilityCheck(AuthorContent, ABILITY_SELF_CONTENT_AUTHORING)

export default Wired
