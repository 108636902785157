import Container from '@mui/material/Container'
import LexicalRenderer from 'common/text/LexicalRenderer'
import withProps from 'hoc/withProps'
import type { SerializedEditorState } from 'lexical'
import Wrapper from './Wrapper'
import type { ContentType } from 'reducers/@types/content'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type ToolbarRenderer = React.ComponentType<{ [key: string]: any }>

type EnhancedLexicalRendererProps = {
  'data-contentid'?: string
  'data-contenttype'?: ContentType
  assignmentSettings?: object // TODO: define "assignmentSettings" type
  bodyJSON: SerializedEditorState
  children?: React.ReactNode
  childrenMetadata?: object[] // TODO: define "block" type
  contained?: boolean
  displayDebugger?: boolean
  previewing?: boolean
  ToolbarRenderer?: ToolbarRenderer
  variant?: string // TODO: narrow down the possible values
}

const fullWidthVariants = ['callout-question', 'ordered-feature-list']

const EnhancedLexicalRenderer: React.FC<
  EnhancedLexicalRendererProps
> = props => {
  const {
    assignmentSettings,
    bodyJSON,
    children: outerChildren,
    childrenMetadata,
    contained = true,
    ToolbarRenderer,
    variant,
    ...rest
  } = props

  const SubBlockWrapper =
    contained && !fullWidthVariants.includes(variant)
      ? Container
      : withProps('div', { className: 'block-full-width' })

  return (
    <Wrapper
      style={{ position: 'relative' }}
      variant={variant}
      {...rest}>
      {outerChildren}

      <LexicalRenderer editorState={bodyJSON} />
    </Wrapper>
  )
}

export default EnhancedLexicalRenderer
