import { CONTENT_TYPE_RUBRIC, CONTENT_TYPE_RUBRIC_CRITERIA } from 'core/consts'
import { arraySequence } from 'fp/arrays'
import { get } from 'fp/objects'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import AuthoringInteractiveForm from 'hss/ContentAuthor/interactives/AuthoringInteractiveForm'
import type { ContentType } from 'hss/ContentBuilder/@types/contentBuilder'
import PreviewButton from 'hss/ContentBuilder/interactives/Form/Preview/PreviewButton'
import { default as RubricContent } from 'hss/ContentBuilder/interactives/Rubric/Content'
import RubricInteractive from 'hss/sections/contentBlocks/interactives/Rubric'
import { produce } from 'immer'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import { getContentByIds } from 'selectors/content'

type RubricFormProps = {
  content: Content
  contentType: ContentType
}

const maxScore = 6
const defaultValues = {
  description: '',
  children: [
    {
      contentType: CONTENT_TYPE_RUBRIC_CRITERIA,
      data: {
        options: arraySequence(maxScore).map(i => ({
          id: generateId(),
          description: '',
          score: i + 1,
        })),
        title: '',
      },
    },
  ],
  maxScore,
  startAtZero: false,
}

const previewButton = (props: RubricFormProps) => (
  <PreviewButton
    Interactive={RubricInteractive}
    additionalInteractiveProps={{}}
    {...props}
  />
)

const Rubric = (formProps: RubricFormProps) => {
  const { content } = formProps
  const children = content?.children
  const rubricChildren = useSelector(
    getContentByIds({ contentIds: children ? children.map(get('id')) : [] }),
  )

  const formPropsWithChildren =
    content && rubricChildren && Array.isArray(rubricChildren)
      ? produce(formProps, draft => {
          draft.content.children = rubricChildren
        })
      : formProps

  return (
    <AuthoringInteractiveForm
      defaultValues={defaultValues}
      formProps={formPropsWithChildren}
      interactiveInstructionsAllowed={false}
      omitAssetCode
      previewButton={previewButton}
      studentInstructionsAllowed={false}>
      <RubricContent />
    </AuthoringInteractiveForm>
  )
}

const RubricWithContent = withContent(Rubric, {
  contentType: CONTENT_TYPE_RUBRIC,
})

export default RubricWithContent
