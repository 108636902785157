import { INTERACTIVE_TYPE_VENN_DIAGRAM } from 'core/consts'
import { set } from 'fp/objects'
import { isDefined } from 'fp/utils'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { useCallback, useContext, useState } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Content from './Content'

const VennDiagram = withQuestionPrompt(props => {
  const { displayResults } = props
  const {
    contentId,
    interactionData,
    interactiveData,
    onInteract,
    submittable,
    submittedDate,
  } = useContext(interactiveContext)

  const { readOnly } = interactiveData

  const [responseState, setResponseState] = useState({
    sections: interactionData?.sections || interactiveData.sections,
  })

  const handleChange = useCallback(
    (field, value) => {
      const newValue = set(field, value)(responseState)
      setResponseState(newValue)
      onInteract(newValue)
    },
    [onInteract, responseState],
  )

  const isReadOnly =
    isDefined(submittedDate) || readOnly || !submittable || displayResults

  return (
    <Content
      {...interactiveData}
      {...responseState}
      contentId={contentId}
      onChange={handleChange}
      readOnly={isReadOnly}
    />
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_VENN_DIAGRAM,
}

export default VennDiagram
