import Centered from 'common/layout/Centered'
import Well from 'common/layout/Well'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getInteractiveShouldBeHidden } from 'selectors/content'
import { interactiveContext } from './InteractiveProvider'

const MaybeHiddenFromStudent = ({ children }) => {
  const {
    interaction,
    interactive: { id: interactiveId },
  } = useContext(interactiveContext)
  const hide = useSelector(
    getInteractiveShouldBeHidden({ interactiveId, interaction }),
  )

  return hide ? (
    <Well>
      <Centered>Details are now hidden for this assignment.</Centered>
    </Well>
  ) : (
    children
  )
}

MaybeHiddenFromStudent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MaybeHiddenFromStudent
