import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledFragmentBox = styled(Box)(
  ({
    theme: {
      mixins: { px },
      palette,
    },
  }) => ({
    background: palette.common.white,
    border: `2px solid ${palette.grey[300]}`,

    '.MuiFormControl-root': {
      marginTop: 0,
      padding: px(12),
    },

    '.MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
)

const FragmentBox = ({ ...rest }) => <StyledFragmentBox {...rest} />

export default FragmentBox
