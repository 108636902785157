import {
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_DOCUMENT,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_VIDEO,
} from 'core/consts'
import { produce } from 'immer'
import { lighten } from 'polished'

/** ****************************************************************************
 *
 *                               !!!!!!!!!!!!!!!!!!
 *                               !!!   NOTICE   !!!
 *                               !!!!!!!!!!!!!!!!!!
 *
 * This is not the complete list of available colors.  Many are inherited and/or
 * derived from Material.
 *
 * For the complete list, see:
 * http://studysync-traverse-storybook.s3-website-us-west-1.amazonaws.com/?path=/story/design-system-palette--palette
 *
 **************************************************************************** */

const grey = {
  0: '#333',
  1: '#4F4F4F',
  2: '#828282',
  3: '#BDBDBD',
  4: '#E0E0E0',
  5: '#F2F2F2',
  hover: '#4F4F4F',
  contrastText: '#fff',
  gradient: 'linear-gradient(100.87deg, #485563 0%, #29323C 101.71%)',
  gradientDark: 'linear-gradient(100.87deg, #29323C 0%, #485563 101.71%)',
  gradientLight: 'linear-gradient(99.85deg, #5D6874 0%, #D9D9D9 100%)',
  name: 'grey',
}

const cobalt = {
  0: '#1C5BFF',
  1: '#4C80FB',
  2: '#799FFC',
  3: '#A5BFFD',
  4: '#D2DFFE',
  hover: '#799FFC',
  contrastText: '#fff',
  gradient: 'linear-gradient(101.07deg, #6EE2F5 0%, #1C5BFF 102.07%)',
  gradientEnd: 'rgb(28, 91, 255, 0.9)',
  gradientStart: 'rgba(110, 226, 245, 0.6)',
  name: 'cobalt',
}

const emerald = {
  0: '#036167',
  1: '#2F8186',
  2: '#5AA2A7',
  3: '#85C2C7',
  4: '#BDE5E9',
  contrastText: '#fff',
  gradient: 'linear-gradient(100.87deg, #2A9FA7 0%, #036167 101.71%)',
  gradientEnd: 'rgb(3, 97, 103, 0.9)',
  gradientStart: 'rgb(42, 159, 167, 0.6)',
  name: 'emerald',
}

const tangerine = {
  0: '#FD8D43',
  1: '#FE9D5D',
  2: '#FFB584',
  3: '#FCC8A6',
  4: '#FFE1CD',
  contrastText: '#fff',
  gradient: 'linear-gradient(99.85deg, #FFCE13 0%, #FD8D43 100%)',
  gradientEnd: '#FD8D43',
  gradientStart: '#FFCE13',
  name: 'tangerine',
}

const babyBlue = {
  0: '#80B7F0',
  1: '#9ECBFA',
  2: '#B8DBFF',
  3: '#CFE7FF',
  4: '#E5F2FF',
  contrastText: '#fff',
  gradient: 'linear-gradient(99deg, #CFE7FF 0%, #80B7F0 100%)',
  gradientEnd: '#80B7F0',
  gradientStart: '#CFE7FF',
  name: 'babyBlue',
}

const teal = {
  0: '#16C8C8',
  1: '#4DD3D2',
  2: '#77DEDD',
  3: '#A4E9E8',
  4: '#D1F4F4',
  contrastText: '#fff',
  gradient: 'linear-gradient(99deg, #90F7EC 0%, #16C8C8 100%)',
  gradientEnd: '#16C8C8',
  gradientStart: '#90F7EC',
  name: 'teal',
}

const canary = {
  0: '#FED538',
  1: '#FFE16C',
  2: '#FFE890',
  3: '#FFF1B9',
  4: '#FFF7DA',
  contrastText: grey[0],
  gradient: 'linear-gradient(99deg, #FFF1B9 0%, #FED538 100%)',
  gradientEnd: '#FED538',
  gradientStart: '#FFF1B9',
  name: 'canary',
}

const violet = {
  0: '#C8A6FF',
  1: '#D0B3FF',
  2: '#D9C1FF',
  3: '#E4D3FF',
  4: '#EFE4FF',
  contrastText: '#fff',
  gradient: 'linear-gradient(99deg, #C8A6FF 0%, #7367F0 100%)',
  gradientEnd: 'rgb(115, 103, 240, 0.9)',
  gradientStart: 'rgb(200, 166, 255, 0.6)',
  name: 'violet',
}

const primary = {
  50: '#e7e7e7',
  100: '#c2c2c2',
  200: '#999999',
  300: '#707070',
  400: '#525252',
  500: '#333333',
  600: '#2e2e2e',
  700: '#272727',
  800: '#202020',
  900: '#141414',
  A100: '#ffffff',
  A200: '#f5f6ff',
  A400: '#c2c9ff',
  A700: '#a8b2ff',
  contrastDefaultColor: 'light',
  contrastDarkColors: ['50', '100', '200', 'A100', 'A200'],
  contrastLightColors: [
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A400',
    'A700',
  ],
}

const paperSecondary = grey[0]

const secondaryLight = '#e4f5fc'
const secondary = babyBlue[1]
const secondaryDark = babyBlue[0]

const common = {
  black: '#000',
  blue: cobalt[0],
  darkRed: '#71062A',
  green: '#02BF86',
  grey: grey[1],
  lightGrey: grey[4],
  orange: tangerine[0],
  purple: violet.gradientEnd,
  red: '#ED2756',
  teal: teal[0],
  yellow: canary[0],
  white: '#fff',
}

const sectionAccents = ['tangerine', 'babyBlue', 'teal', 'emerald']

const wordCloud = [
  cobalt[0],
  babyBlue[0],
  emerald[0],
  teal[0],
  tangerine[0],
  canary[0],
]
/**
 * NOTE:
 * There are multiple mappings for "baby blue" for convenience of ingestion, so "baby blue", "babyblue", and "babyBlue" will
 * all be the same shade of blue.  If this causes issue, please check before removing.
 */
const charting = {
  'baby blue': babyBlue[0],
  babyblue: babyBlue[0],
  babyBlue: babyBlue[0],
  canary: canary[1],
  cobalt: cobalt[1],
  emerald: emerald[1],
  grey: grey[0],
  tangerine: tangerine[1],
  teal: teal[1],
  violet: violet.gradientEnd,
  white: common.white,
}

const carousel = [
  { start: violet.gradientStart, end: violet.gradientEnd },
  { start: cobalt.gradientStart, end: cobalt.gradientEnd },
  { start: emerald.gradientStart, end: emerald.gradientEnd },
]

const chartDefaults = [
  charting.babyBlue,
  charting.cobalt,
  charting.teal,
  charting.emerald,
  charting.canary,
  charting.tangerine,
  charting.violet,
]

const pacingGuide = {
  skillDefault: emerald[0],
}

const palette = {
  babyBlue,
  background: {
    default: '#f9f9f9',
    paperSecondary,
    inverted: grey[0],
    dark: common.black,
  },
  border: grey,
  boxshadowPrimary: cobalt[0],
  canary,
  carousel,
  chartDefaults,
  charting,
  cobalt,
  common,
  contrastText: '#fff',
  disabled: {
    background: grey[4],
    border: 'transparent',
  },
  drawer: '#1b1b1b',
  echo: {
    background: grey[1],
    decorative: canary[0],
    selected: grey[4],
    subsection: canary[0],
    text: canary[0],
    toc: tangerine[0],
    tocDark: tangerine[0],
  },
  emerald,
  error: {
    contrastText: '#fff',
    dark: '#71062A',
    light: '#FFE2E9',
    main: '#ED2756',
  },
  focused: cobalt[2],
  grey,
  navigation: {
    toc: tangerine[0],
    tocLight: tangerine[0],
    main: grey[3],
    icon: grey[1],
  },
  pacingGuide,
  pagination: tangerine[0],
  primary,
  secondary: {
    dark: secondaryDark,
    light: secondaryLight,
    main: secondary,
  },
  sectionAccents,
  selected: primary[900],
  selection: cobalt[2],
  shadow: '#111', // Used only as a seed for /shadows.js
  slider: {
    main: grey[3],
    border: 'transparent',
    disabled: grey[3],
  },
  success: {
    contrastText: '#fff',
    dark: '#14694F',
    light: '#C1EDE0',
    main: '#02BF86',
  },
  tableCell: grey[5],
  tangerine,
  teal,
  teacherEdition: tangerine[0],
  text: {},
  video: {
    main: cobalt[0],
    contrast: '#fff',
    bar: 'rgba(0,0,0,.5)',
  },
  violet,
  wordCloud,
}

// MARK: assembly
const postProcess = produce(draft => {
  draft.palette.accentColor = draft.accentColor

  const accent = draft.palette[draft.accentColor]
  draft.palette.accent = {
    dark: accent[0],
    light: accent[4],
    main: accent[2],
    ...accent,
  }

  const builtGrey = draft.palette.grey
  draft.palette.important = draft.palette.error.main
  draft.palette.border[0] = builtGrey[3]
  draft.palette.border[1] = builtGrey[4]
  draft.palette.border[2] = builtGrey[5]
  draft.palette.border[3] = lighten(0.22, builtGrey[3])
  draft.palette.border[4] = lighten(0.1, builtGrey[4])
  draft.palette.border[5] = lighten(0.1, builtGrey[5])
  draft.palette.border.light = builtGrey[2]
  draft.palette.border.dark = builtGrey[0]
  draft.palette.border.interactive = 'transparent'

  draft.palette.text.primary = grey[0]
  draft.palette.text.secondary = grey[1]
  draft.palette.text.disabled = grey[3]
  draft.palette.primary.contrastText = grey.contrastText

  draft.palette.annotations = [
    { colorId: 1, value: common.red, label: 'Red' },
    { colorId: 2, value: common.orange, label: 'Orange' },
    { colorId: 3, value: common.yellow, label: 'Yellow' },
    { colorId: 4, value: common.green, label: 'Green' },
    { colorId: 5, value: common.teal, label: 'Teal' },
    { colorId: 6, value: common.blue, label: 'Blue' },
  ]

  draft.palette.gradebook = [
    { colorId: 1, value: common.yellow, label: 'Yellow', textColor: grey[0] },
    {
      colorId: 2,
      value: common.purple,
      label: 'Purple',
      textColor: common.white,
    },
    { colorId: 3, value: common.orange, label: 'Orange', textColor: grey[0] },
    { colorId: 4, value: common.blue, label: 'Blue', textColor: common.white },
    { colorId: 5, value: common.red, label: 'Red', textColor: common.white },
    { colorId: 6, value: common.green, label: 'Green', textColor: grey[0] },
    {
      colorId: 7,
      value: common.black,
      label: 'Black',
      textColor: common.lightGrey,
      hoverColor: common.grey,
    },
    { colorId: 8, value: grey[3], label: 'Gray', textColor: grey[0] },
  ]

  draft.palette.pictureThisTextBackground = [
    {
      colorId: 1,
      value: common.black,
      contrastText: common.white,
      label: 'Black',
    },
    { colorId: 2, value: common.red, contrastText: common.black, label: 'Red' },
    {
      colorId: 3,
      value: common.orange,
      contrastText: common.black,
      label: 'Orange',
    },
    {
      colorId: 4,
      value: common.yellow,
      contrastText: common.black,
      label: 'Yellow',
    },
    {
      colorId: 5,
      value: common.green,
      contrastText: common.black,
      label: 'Green',
    },
    {
      colorId: 6,
      value: common.blue,
      contrastText: common.black,
      label: 'Blue',
    },
    {
      colorId: 7,
      value: common.purple,
      contrastText: common.black,
      label: 'Purple',
    },
  ]

  draft.palette.libraryItems = {
    AcademicVocabulary: emerald[1],
    Assessment: tangerine[1],
    [INTERACTIVE_TYPE_AUDIO]: emerald[1],
    [CONTENT_TYPE_CHAPTER]: tangerine[1],
    [CONTENT_TYPE_ECHO]: canary[0],
    [CONTENT_TYPE_INTERACTIVE]: tangerine[1],
    [CONTENT_TYPE_SECTION]: cobalt[1],
    [CONTENT_TYPE_SOURCE]: violet[1],
    [CONTENT_TYPE_SUBSECTION]: babyBlue[0],
    DBI: tangerine[1],
    Document: tangerine[1],
    [INTERACTIVE_TYPE_DOCUMENT]: tangerine[1],
    [INTERACTIVE_TYPE_ESSAY]: tangerine[1],
    [INTERACTIVE_TYPE_IMAGE]: babyBlue[0],
    [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: tangerine[1],
    [INTERACTIVE_TYPE_POLL]: tangerine[1],
    PictureThis: canary[1],
    Skill: teal[2],
    Text: tangerine[1],
    [INTERACTIVE_TYPE_VIDEO]: teal[1],
    VocabularyPreview: canary[1],
  }
})

export default palette

export { postProcess }
