import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Html from 'common/text/Html'
import { a11yContext } from 'core/a11y'
import { matches } from 'fp/utils'
import useEffectOnce from 'hooks/useEffectOnce'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useContext, useRef } from 'react'
import { ArrowDown, ArrowUp, Edit2, XCircle } from 'react-feather'
import Option from '../DragAndDrop/Option'
import { itemShape, responseItemShape } from '../utils'

const SelectedResponse = ({ dispatch, item, onSelectItem, responseItem }) => {
  const isSmallScreen = useContainerQuery().down('sm')
  const {
    interactiveData: { columns, items, rows },
  } = useContext(interactiveContext)

  const moveableItems = items.filter(
    ({ isStatic, column }) => !isStatic && column === item.column,
  )

  const handleRemove = () => {
    dispatch({ type: 'REMOVE_ITEM', item: responseItem })
  }
  const handleMove = direction => () => {
    dispatch({
      type: 'MOVE_ITEM',
      selectedItem: responseItem,
      item,
      moveableItems,
      direction,
    })
  }

  const column = columns.findIndex(matches('id', item.column)) + 1
  const row = rows.findIndex(matches('id', item.row)) + 1

  const buttonRef = useRef()

  useEffectOnce(() => {
    buttonRef.current?.focus()
  }, [])

  const { tabModeEnabled } = useContext(a11yContext) || {}

  return isSmallScreen || tabModeEnabled ? (
    <Box
      border={1}
      borderColor="border.0"
      p={1}>
      <Html body={responseItem?.value} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <IconButton
            aria-label={`move response up for column ${column}, row ${row}`}
            color="primary"
            disabled={moveableItems.findIndex(({ id }) => id === item.id) === 0}
            onClick={handleMove(-1)}>
            <ArrowUp />
          </IconButton>
          <IconButton
            aria-label={`move response down for column ${column}, row ${row}`}
            color="primary"
            disabled={
              moveableItems.findIndex(({ id }) => id === item.id) ===
              moveableItems.length - 1
            }
            onClick={handleMove(1)}>
            <ArrowDown />
          </IconButton>
        </div>
        <div>
          <IconButton
            color="primary"
            onClick={() => onSelectItem(item)}>
            <Edit2 />
          </IconButton>
          <IconButton
            aria-label="Remove response"
            onClick={handleRemove}
            ref={buttonRef}
            size="small">
            <XCircle />
          </IconButton>
        </div>
      </div>
    </Box>
  ) : (
    <Option
      component="div"
      item={responseItem}
      key={responseItem.id}>
      <IconButton
        aria-label="Remove response"
        onClick={handleRemove}
        ref={buttonRef}
        size="small">
        <XCircle />
      </IconButton>
    </Option>
  )
}

SelectedResponse.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: itemShape.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  responseItem: responseItemShape.isRequired,
}

export default SelectedResponse
