import {
  ABILITY_SELF_CONTENT_AUTHORING,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_VOCABULARY,
} from 'core/consts'
import { push } from 'fp/arrays'
import { isDefined, matches, when } from 'fp/utils'
import withContent from 'hoc/withContent'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useContent from 'hooks/useContent'
import { buildContentUrl, getLabel } from 'hss/ContentBuilder/utils'
import BreadcrumbsRenderer, {
  DIRECTIVE_NEEDS_SUB_CONTENT_TYPE,
} from 'hss/sections/Breadcrumbs/BreadcrumbsRenderer'
import { useEffect, useState } from 'react'
import type { Content } from 'reducers/@types/content'
import {
  contentBuilderUrl,
  curriculumUrl,
  getTeacherContentAuthoringRoute,
  libraryUrl,
} from 'routing/consts'

type BreadCrumb = {
  breadcrumb: string
  key:
    | string
    | {
        directive: string
        url: string
        contentType: string
        contentId?: string
      }
  contentType?: string
  id?: string
  teacherEdition?: boolean
  title?: string
}

const carveOutsForLibrary = [
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_VOCABULARY,
]

const maybeAddInteractive = (acc: BreadCrumb[], content: Content) => {
  const { contentType, id, name } = content

  if (contentType === CONTENT_TYPE_INTERACTIVE) {
    acc.push({
      breadcrumb: 'Interactive',
      title: name || id,
      key: {
        directive: DIRECTIVE_NEEDS_SUB_CONTENT_TYPE,
        url: `${contentBuilderUrl}/${contentType}/__subContentType__/${id}`,
        contentType,
        contentId: id,
      },
    })
  }
}

const getBreadcrumbs = (
  acc: BreadCrumb[],
  content: Content = {},
  isTabbed: boolean | undefined = false,
  hasContentAuthoring: boolean | undefined = false,
) => {
  const { contentSubType, contentType, id, name, parent, teacherEdition } =
    content

  maybeAddInteractive(acc, content)

  const buildUrl = hasContentAuthoring
    ? getTeacherContentAuthoringRoute
    : buildContentUrl

  if (contentSubType && contentType !== CONTENT_TYPE_VOCABULARY) {
    acc.push({
      breadcrumb: getLabel({ contentType, contentSubType }),
      contentType,
      id,
      teacherEdition,
      title: name || id,
      key: buildUrl({ contentType, contentSubType, id }),
    })
  } else if (carveOutsForLibrary.includes(contentType)) {
    acc.push({
      breadcrumb:
        parent?.contentType === CONTENT_TYPE_SECTION &&
        contentType === CONTENT_TYPE_SECTION
          ? 'DBI Section'
          : getLabel({ contentType }),
      contentType,
      id,
      teacherEdition,
      title: name || id,
      key: buildUrl({ contentType, contentSubType, id }),
    })
  } else if (contentType !== CONTENT_TYPE_INTERACTIVE) {
    if (contentType === CONTENT_TYPE_SUBSECTION && isTabbed) {
      acc.push({
        breadcrumb: 'Tab',
        contentType,
        id,
        teacherEdition,
        title: name || id,
        key: buildUrl({ contentType, contentSubType, id }),
      })
    } else {
      acc.push({
        breadcrumb:
          parent?.contentType === CONTENT_TYPE_SECTION &&
          contentType === CONTENT_TYPE_SECTION
            ? 'DBI Section'
            : getLabel({ contentType }),
        contentType,
        id,
        teacherEdition,
        title: name || id,
        key: buildUrl({ contentType, contentSubType, id }),
      })
    }
  }
  when(parent, getBreadcrumbs, acc, parent, isTabbed, hasContentAuthoring)
  return acc
}

type CurriculumBuilderBreadcrumbs = {
  content: Content
  rootBreadcrumb?: BreadCrumb
}

const CurriculumBuilderBreadcrumbs = ({
  content,
  rootBreadcrumb,
}: CurriculumBuilderBreadcrumbs) => {
  const { contentType } = content || {}

  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb[]>([])
  const [sectionId, setSectionId] = useState()
  const [isTabbed, setIsTabbed] = useState(false)

  const hasContentAuthoring = useAbilityCheck(ABILITY_SELF_CONTENT_AUTHORING)

  const section = useContent({
    contentType: CONTENT_TYPE_SECTION,
    contentId: sectionId,
    /**
     * We won't always have sectionId, and when we don't, we don't want useContent
     * to go trying to guess at the content id based on the url and such.  We
     * can't conditionally run this hook, but disabling fetching has the same effect.
     */
    disableFetch: true,
  })

  useEffect(() => {
    const rootBC = carveOutsForLibrary.includes(contentType)
      ? {
          breadcrumb: 'Library',
          key: libraryUrl,
        }
      : isDefined(rootBreadcrumb)
        ? rootBreadcrumb
        : {
            breadcrumb: 'Curriculum',
            key: curriculumUrl,
          }

    const built = push(rootBC)(
      getBreadcrumbs([], content || {}, isTabbed, hasContentAuthoring),
    ).reverse()

    const { id } =
      built.find(matches('contentType', CONTENT_TYPE_SECTION)) || {}
    setSectionId(id)
    setIsTabbed(section?.data?.tabbed)

    setBreadcrumbs(built)
  }, [
    content,
    contentType,
    isTabbed,
    rootBreadcrumb,
    section?.data,
    hasContentAuthoring,
  ])

  return content ? <BreadcrumbsRenderer breadcrumbs={breadcrumbs} /> : null
}

const BreadcrumbsWithContent = withContent(CurriculumBuilderBreadcrumbs, {
  busy: 'silent',
  queryParams: { childDepth: 2 },
})

export default BreadcrumbsWithContent
