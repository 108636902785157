import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { useContext } from 'react'
import { searchContext } from '../../SearchProvider'
import FilterButtons from '../FilterButtons'

const ContentSubTypeFilter = () => {
  const { alterContentSubTypeFilters, contentSubTypes, dataset } =
    useContext(searchContext)

  const squery = get('metadata.squery')(dataset)

  const selectedFilters = inspect(squery).get.where('contentSubType').in()

  const areAllSelected =
    selectedFilters.length === contentSubTypes.length &&
    selectedFilters.length > 1

  return (
    <FilterButtons
      filters={contentSubTypes}
      handleFilterClick={alterContentSubTypeFilters}
      selectedFilters={areAllSelected ? [] : selectedFilters}
      subContentType={contentSubTypes[0]}
    />
  )
}

export default ContentSubTypeFilter
