import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledAnswerChoiceBox = styled(Box)(
  ({
    theme: {
      mixins: { px, transition },
      palette,
      shadows,
    },
  }) => ({
    ...transition(),
    paddingRight: px(5),
    svg: {
      overflow: 'visible',
    },
    '&:hover': {
      backgroundColor: palette.cobalt[4],
      boxShadow: shadows.border,
      color: palette.boxshadowPrimary,
      svg: {
        fill: palette.common.white,
        strokeWidth: 2,
        path: {
          fill: 'none',
        },
        rect: {
          color: palette.cobalt[0],
        },
      },
    },
    '&:active': {
      ...transition(),
      backgroundColor: palette.cobalt[4],
      boxShadow: `${shadows.activeBorder} ${palette.boxshadowPrimary}, ${shadows.border} ${palette.boxshadowPrimary}`,
      color: palette.cobalt[0],
    },
    '.MuiFormControlLabel-root': {
      margin: 0,
      width: '100%',

      '.MuiTypography-root': {
        width: '100%',
      },
      '.MuiCheckbox-root': {
        display: 'none',
      },
      '.MuiRadio-root': {
        display: 'none',
      },
    },
  }),
)

const AnswerChoiceBox = ({ ...rest }) => <StyledAnswerChoiceBox {...rest} />

export default AnswerChoiceBox
