import { toKeyedObject } from 'fp/arrays'
import { get } from 'fp/objects'
import { curryRight, identity } from 'fp/utils'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import ClickableElement from './ClickableElement'
import VennDiagramText from './VennDiagramText'
import { hoverRegions, orderedSections } from './config'
import {
  threeCircleTextPositioning,
  twoCircleTextPositioning,
} from './textConfig'

const VennSections = props => {
  const {
    circles,
    contentId,
    onClick,
    positioning,
    readOnly = false,
    sections,
    showSampleResponse,
  } = props

  const textPositioning =
    circles === 2
      ? twoCircleTextPositioning(positioning)
      : threeCircleTextPositioning(positioning)

  const sectionsByKey = compose(curryRight(toKeyedObject, 'circleIds'))(
    orderedSections,
  )

  const { circles: circlePositions, fontSize } = positioning

  return sections
    .filter(
      circles === 2 ? ({ circleIds }) => !circleIds.includes(3) : identity,
    )
    .map(({ circleIds, header, sampleResponse, response }) => {
      const { description, key } = get(`${circleIds}`)(sectionsByKey)
      const clipIndex = get(`${circles}.${key}`)(hoverRegions)
      const { center, radius } = circlePositions[clipIndex]
      return (
        <g
          className="section"
          key={key}>
          {Boolean(!readOnly) && (
            <ClickableElement
              aria-describedby={`${key}-description`}
              aria-label={`Edit ${header ? `${header}, ` : ''}${description} section`}
              clipPath={`url(#${contentId}-${key}-clip)`}
              cx={center.x}
              cy={center.y}
              onClick={onClick({ circleIds })}
              r={radius}
              readOnly={readOnly}
              type="circle">
              <title>
                {description}
                {'\n'}
                {response}
              </title>
            </ClickableElement>
          )}
          <VennDiagramText
            fontSize={fontSize}
            readOnly={readOnly}
            {...textPositioning[key]}>
            {showSampleResponse ? sampleResponse : response}
          </VennDiagramText>
        </g>
      )
    })
}
VennSections.propTypes = {
  circles: PropTypes.number.isRequired,
  contentId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  positioning: PropTypes.shape({
    circles: PropTypes.arrayOf(
      PropTypes.shape({
        center: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }).isRequired,
        radius: PropTypes.number.isRequired,
      }),
    ).isRequired,
    fontSize: PropTypes.number.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      circleIds: PropTypes.array.isRequired,
      header: PropTypes.string,
      response: PropTypes.string,
      sampleResponse: PropTypes.string,
    }),
  ).isRequired,
}

export default VennSections
