import Box from '@mui/material/Box'
import cl from 'classnames'
import { DND_TYPE_CHART_COMPLETION } from 'core/consts'
import { pick } from 'fp/objects'
import { isDefined } from 'fp/utils'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { actions, itemShape, responseItemShape } from '../utils'
import Input from './Input'
import SelectResponseButton from './SelectResponseButton'
import Text from './Text'

const Renderers = {
  text: Text,
  input: Input,
}

const ChartItem = props => {
  const { dispatch, heading, item, onSelectItem, readOnly, responseItem } =
    props
  const { contentType, isStatic } = item
  const Renderer = Renderers[contentType]
  const handleDrop = droppedItem => {
    dispatch({
      type: actions.DROP_ITEM,
      item: { id: droppedItem.id, ...pick(['row', 'column'])(item) },
    })
  }
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: DND_TYPE_CHART_COMPLETION,
    drop: handleDrop,
    canDrop: () => !(readOnly || isStatic || responseItem),
    collect: monitor => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
  })
  const focusRef = useRef()
  const handleCellClick = () => {
    focusRef.current?.focus()
  }

  return (
    <Box
      className={cl({
        'drag-hover': canDrop && !isStatic && !isOver,
        static: isStatic,
        draggable: !isStatic,
        hovered: canDrop && isOver,
      })}
      component="td"
      data-title={heading}
      onClick={handleCellClick}
      ref={dropRef}>
      {isStatic || isDefined(responseItem) || readOnly ? (
        <Renderer
          {...props}
          ref={contentType === 'input' ? focusRef : undefined}
        />
      ) : (
        <SelectResponseButton
          columnName={heading}
          onClick={() => {
            onSelectItem(item)
          }}
        />
      )}
    </Box>
  )
}

ChartItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  item: itemShape.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responseItem: responseItemShape,
}

export default ChartItem
