import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import { useTheme } from '@mui/material/styles'
import Checkbox from 'common/formControls/switches/Checkbox'
import Html from 'common/text/Html'
import { componentShape } from 'core/shapes'
import { filter, map, reduce, xDifference } from 'fp/arrays'
import { equals, filterKeyedObject, get, set } from 'fp/objects'
import { curryRight, fallbackTo, identity, pipe } from 'fp/utils'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { compose } from 'redux'
import { getCorrectAnswerChoiceIds } from 'selectors/multipleChoice'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import AnswerChoiceBox from './AnswerChoiceBox'
import BaseSelect from './BaseSelect'

const arrayToObjectKeys = compose(
  reduce((result, id) => set(id, true)(result), {}),
  fallbackTo([]),
)

const onlyTruthyKeys = compose(
  Object.keys,
  curryRight(filterKeyedObject, identity),
)

const ItemRenderer = ({
  checked = false,
  direction = '',
  disabled = false,
  explanation = '',
  isCorrect = false,
  isSelectable = false,
  label = '',
  selectedAnswersAreCorrect = false,
  ...rest
}) => {
  const itemIsAnsweredCorrectly = checked === isCorrect
  const {
    completed,
    scoreData: { hidden: isScoreHidden } = {},
    submittable,
    isPastSubmissionDate,
  } = useContext(interactiveContext)

  const showAnswers =
    useIsInAnswerKeyContext() ||
    ((completed || isPastSubmissionDate) && !isScoreHidden)

  const { palette, shadows } = useTheme()
  const noBoxShadow = { boxShadow: 'none' }

  const getStyle = () => {
    let bgColor = 'transparent'
    if (selectedAnswersAreCorrect && checked) {
      bgColor = 'success.main'
    } else if (itemIsAnsweredCorrectly) {
      bgColor = 'transparent'
    } else {
      bgColor = 'error.main'
    }

    const boxShadow =
      showAnswers || !isSelectable
        ? { ...noBoxShadow, pointerEvents: 'none' }
        : checked
          ? {
              boxShadow: shadows.border,
              color: palette.boxshadowPrimary,
            }
          : disabled
            ? { pointerEvents: 'none' }
            : {}

    return {
      background: bgColor,
      ...boxShadow,
    }
  }

  return (
    <AnswerChoiceBox style={getStyle()}>
      <Checkbox
        checked={checked}
        disabled={disabled || !submittable || !isSelectable}
        {...rest}
        label={
          <Box
            color="grey.0"
            component="span">
            <Html
              body={label}
              substituteInlineBlocks
            />
          </Box>
        }
      />
    </AnswerChoiceBox>
  )
}

ItemRenderer.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  explanation: PropTypes.string,
  isCorrect: PropTypes.bool,
  label: componentShape.isRequired,
  selectedAnswersAreCorrect: PropTypes.bool,
}

const CheckboxGroup = ({
  answerChoiceStates,
  handleChange,
  limitReached = false,
  options,
  selectedAnswersAreCorrect = false,
}) => (
  <FormGroup>
    {options.map(option => {
      const { id } = option
      return createElement(ItemRenderer, {
        ...option,
        checked: compose(Boolean, get(String(id)))(answerChoiceStates),
        disabled:
          limitReached &&
          !compose(Boolean, get(String(id)))(answerChoiceStates),
        id: String(id),
        key: id,
        onChange: () => handleChange(id),
        selectedAnswersAreCorrect,
      })
    })}
  </FormGroup>
)

CheckboxGroup.propTypes = {
  answerChoiceStates: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  limitReached: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectedAnswersAreCorrect: PropTypes.bool,
}

const MultiSelect = () => {
  const {
    interactionData: { selectedAnswerChoiceIds: userSelectedAnswerChoiceIds },
    interactiveData,
    interactiveData: { answerChoices = [], limitSelectionsTo },
    onInteract,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const showAnswerKey = useIsInAnswerKeyContext()
  const selectedAnswerChoiceIds = useMemo(
    () =>
      showAnswerKey
        ? getCorrectAnswerChoiceIds(interactiveData)
        : userSelectedAnswerChoiceIds,
    [interactiveData, showAnswerKey, userSelectedAnswerChoiceIds],
  )

  const [answerChoiceStates, setAnswerChoiceStates] = useState({})
  useLayoutEffect(() => {
    setAnswerChoiceStates(arrayToObjectKeys(selectedAnswerChoiceIds))
  }, [selectedAnswerChoiceIds])
  const answered = Boolean(
    selectedAnswerChoiceIds?.length &&
      onlyTruthyKeys(answerChoiceStates).length,
  )

  const handleChange = useCallback(
    answerChoiceId => {
      const newAnswerChoiceStates = set(
        answerChoiceId,
        !answerChoiceStates[answerChoiceId],
      )(answerChoiceStates)
      setAnswerChoiceStates(newAnswerChoiceStates)
      onInteract({
        selectedAnswerChoiceIds: onlyTruthyKeys(newAnswerChoiceStates),
      })
    },
    [answerChoiceStates, onInteract],
  )

  const limitReached =
    !!limitSelectionsTo &&
    onlyTruthyKeys(answerChoiceStates).length >= limitSelectionsTo

  useEffect(() => {
    setSubmitEnabled(answered)
  }, [answered, setSubmitEnabled])

  const selectedAnswersAreCorrect = pipe(
    filter(get('isCorrect')),
    map(get('id')),
    map(String),
    xDifference(selectedAnswerChoiceIds),
    get('length'),
    equals(0),
  )(answerChoices)

  const GroupRenderer = useMemo(
    () =>
      withProps(CheckboxGroup, {
        answerChoiceStates,
        handleChange,
        limitReached,
        selectedAnswersAreCorrect,
      }),
    [answerChoiceStates, handleChange, limitReached, selectedAnswersAreCorrect],
  )

  return <BaseSelect GroupRenderer={GroupRenderer} />
}

export default MultiSelect
