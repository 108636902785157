import { produce } from 'immer'
import draftjs from 'styling/thirdParty/draftjs'
import lexical from 'styling/thirdParty/lexical'
import notistack from 'styling/thirdParty/notistack'
import splitterLayout from 'styling/thirdParty/react-splitter-layout'
import recharts from 'styling/thirdParty/recharts'
import swiper from 'styling/thirdParty/swiper'
import videoJs from 'styling/thirdParty/video-js'
import 'feather-webfont/dist/feather-icons.css'
import type { Theme } from '@mui/material'

const plugin = (theme: Theme) =>
  produce(theme, draft => {
    draft.thirdParty = {
      ...draftjs(theme),
      ...lexical(theme),
      ...notistack(theme),
      ...recharts(theme),
      ...splitterLayout(theme),
      ...swiper(theme),
      ...videoJs(theme),
    } as React.CSSProperties
  })

export default plugin
