import Button from '@mui/material/Button'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import Centered from 'common/layout/Centered'
import { ABILITY_CONTENT_EDITING } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import PropTypes from 'prop-types'
import { useContext, useEffect, useMemo } from 'react'
import { PieChart } from 'react-feather'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'

const DisplayPoll = withQuestionPrompt(({ onShowResultsClick }) => {
  const {
    interactionData: { selectedValue = null },
    interactiveData: { allowMultipleSelection },
    isGrading,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const [selectedValueState, setSelectedValue] =
    useStateWithDynamicDefault(selectedValue)

  useEffect(() => {
    setSubmitEnabled(
      !!selectedValue &&
        (!Array.isArray(selectedValue) || selectedValue.length > 0),
    )
  }, [selectedValue, setSubmitEnabled])

  const PollSelect = useMemo(
    () => (allowMultipleSelection ? MultiSelect : SingleSelect),
    [allowMultipleSelection],
  )

  const isContentEditor = useAbilityCheck(ABILITY_CONTENT_EDITING)

  return (
    <ContainedFormContent m="0 auto">
      {Boolean(isGrading && !selectedValue) && (
        <Centered component="p">(no response)</Centered>
      )}

      <PollSelect
        selectedValueState={selectedValueState}
        setSelectedValue={setSelectedValue}
      />

      {Boolean(isContentEditor) && (
        <Centered my={3}>
          <Button
            color="primary"
            onClick={onShowResultsClick}
            startIcon={<PieChart />}
            variant="primary">
            View Results
          </Button>
        </Centered>
      )}
    </ContainedFormContent>
  )
})

DisplayPoll.propTypes = {
  onShowResultsClick: PropTypes.func.isRequired,
}

export default DisplayPoll
