import {
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { flatten, isEmpty, map, reduce } from 'fp/arrays'
import { equals, filterKeyedObject, get, hasProperty, set } from 'fp/objects'
import { fallbackTo, matches } from 'fp/utils'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import { produce } from 'immer'
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import type { Assignment } from 'reducers/@types/assignment'
import type { Content, ContentRestriction } from 'reducers/@types/content'
import { compose } from 'redux'
import { getContextualAssignment } from 'selectors/assignments'
import { getContentNav } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'

type Lookup = Record<string, boolean>

type AssignmentEditContext = {
  excludedContentIds: string[]
  includeChapterSummary: boolean
  isContentIdExcluded: (id: string) => boolean
  leveledNarrativeTextSetting: boolean
  sections: Content[]
  setIncludeChapterSummary: (value: boolean) => void
  setLeveledNarrativeTextSetting: (value: boolean) => void
  toggleExcludeContentId: (id: string) => void
}

// biome-ignore lint/style/noNonNullAssertion: <allow for contexts>
const assignmentEditContext = createContext<AssignmentEditContext>(null!)

type ArrayToLookup = (array: string[]) => Lookup
const arrayToLookup: ArrayToLookup = reduce(
  (result: Lookup, next: string) => set(next, true)(result),
  {},
)

const findContent = (id: string, nodes: Content[]): Content | null => {
  if (!nodes?.length) {
    return null
  }
  const match = nodes.find(matches('id', id))
  return (
    match ||
    compose(
      children => findContent(id, children),
      flatten,
      map(get('children')),
    )(nodes)
  )
}

const useAutoHiddenContentIds = (sections: Content[]) =>
  useMemo(() => {
    const contentRestrictions: Record<string, ContentRestriction | undefined> =
      {}
    const flattenChildren = (item: Content): Content[] => [
      item,
      ...flatten(item?.children?.map(flattenChildren)),
    ]
    for (const section of sections) {
      const children = flattenChildren(section)
        .filter(hasProperty('contentRestriction'))
        .filter(
          compose(
            //
            equals(CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT),
            get('contentRestriction.type'),
          ),
        )
      for (const child of children) {
        contentRestrictions[child.id] = child.contentRestriction
      }
    }

    return Object.keys(contentRestrictions)
  }, [sections])

type AssignmentEditContextProviderProps = {
  children: React.ReactNode
}

const AssignmentEditContextProvider = ({
  children: providerChildren,
}: AssignmentEditContextProviderProps) => {
  const assignment: Assignment = useSelector(getContextualAssignment) || {}
  const { excludedContentIds: initiallyExcludedContentIds } = assignment

  const initiallyIncludeChapterSummary: boolean = get(
    'data.settings.includeChapterSummary',
  )(assignment)
  const initialLeveledNarrativeTextSetting: boolean = get(
    'data.settings.leveledNarrativeText',
  )(assignment)

  const [includeChapterSummary, setIncludeChapterSummary] = useState(
    initiallyIncludeChapterSummary,
  )
  const [leveledNarrativeTextSetting, setLeveledNarrativeTextSetting] =
    useState(initialLeveledNarrativeTextSetting)

  const [excludedContentIdLookup, setExcludedContentIdLookup] =
    useState<Lookup>(
      compose<Lookup>(
        arrayToLookup,
        fallbackTo([]),
      )(initiallyExcludedContentIds),
    )

  const { contentId }: { contentId: string } =
    useSelector(getContentViewerParams()) || {}
  const sections: Content[] = useSelector(
    getContentNav({
      contentId,
      leafContentTypes: SECTION_CONTENT_TYPES,
    }),
  )

  const updateExcludedContentIds = useCallback(
    (newExcludedContentIds: Lookup) => {
      setExcludedContentIdLookup(
        produce(newExcludedContentIds, draft => {
          const setSelectionBasedOnChildren = ({ children, id }: Content) => {
            if (children?.length) {
              for (const child of children) {
                setSelectionBasedOnChildren(child)
              }
              const selectedChildren = children.filter(
                ({ id: childId }) => draft[childId],
              )
              draft[id] = selectedChildren.length === children.length
            }
          }
          for (const section of sections || []) {
            setSelectionBasedOnChildren(section)
          }
        }),
      )
    },
    [sections],
  )

  const isContentIdExcluded = useCallback(
    (id: string) => !!excludedContentIdLookup[id],
    [excludedContentIdLookup],
  )

  const excludedContentIds = useDeepCompareMemo(
    () => Object.keys(filterKeyedObject(excludedContentIdLookup, Boolean)),
    [excludedContentIdLookup],
  )

  const selectContent = useCallback(
    (id: string, select: boolean) => {
      updateExcludedContentIds(
        produce(excludedContentIdLookup, draft => {
          const updateChildrenRecursive = ({ children = [] }: Content) => {
            for (const child of children) {
              draft[child.id] = select
              updateChildrenRecursive(child)
            }
          }
          draft[id] = select
          const foundContent = findContent(id, sections)
          if (foundContent) {
            updateChildrenRecursive(foundContent)
          }
        }),
      )
    },
    [excludedContentIdLookup, sections, updateExcludedContentIds],
  )

  const toggleExcludeContentId = useCallback(
    (id: string) => {
      selectContent(id, !excludedContentIdLookup[id])
    },
    [excludedContentIdLookup, selectContent],
  )

  const initialized = useRef(false)
  const initiallyHiddenContentIds = useAutoHiddenContentIds(sections)
  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: reads better this way (imo)
    if (!isEmpty(sections) && !assignment.id && !initialized.current) {
      initialized.current = true
      for (const id of initiallyHiddenContentIds) {
        selectContent(id, true)
      }
    }
  }, [assignment.id, initiallyHiddenContentIds, sections, selectContent])

  const value = useMemo<AssignmentEditContext>(
    () => ({
      excludedContentIds,
      includeChapterSummary,
      isContentIdExcluded,
      leveledNarrativeTextSetting,
      sections,
      setIncludeChapterSummary,
      setLeveledNarrativeTextSetting,
      toggleExcludeContentId,
    }),
    [
      excludedContentIds,
      includeChapterSummary,
      isContentIdExcluded,
      leveledNarrativeTextSetting,
      sections,
      toggleExcludeContentId,
    ],
  )

  return (
    <assignmentEditContext.Provider value={value}>
      {providerChildren}
    </assignmentEditContext.Provider>
  )
}

export { assignmentEditContext, AssignmentEditContextProvider }

export type { AssignmentEditContext }
