import { createElement, useContext } from 'react'
import { INTERACTIVE_TYPE_SENTENCE_FRAGMENT } from 'shared/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'

const SentenceFragment = () => {
  const {
    interactiveData: { allowMultipleSelection },
  } = useContext(interactiveContext)
  return createElement(allowMultipleSelection ? MultiSelect : SingleSelect)
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_SENTENCE_FRAGMENT,
}

export default SentenceFragment
