import { push } from 'fp/arrays'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import { labels } from 'hss/ContentBuilder/consts'
import { getLabel } from 'hss/ContentBuilder/utils'
import { compose } from 'redux'
import { contentAuthorUrl, libraryUrl } from 'routing/consts'
import { CONTENT_TYPE_RUBRIC } from 'shared/consts'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import BreadcrumbsRenderer from '../BreadcrumbsRenderer'

const labeller = (verb: string) =>
  compose(prefix(verb), prefix(' '), getLabel, get('match.params'))

const breadcrumbConfig = [
  {
    path: `${contentAuthorUrl}/create/:contentType/type/:contentSubType`,
    breadcrumb: labeller('New'),
  },
  {
    path: `${contentAuthorUrl}/edit/:contentType/type/:contentSubType/:contentId`,
    breadcrumb: labeller('Edit'),
  },

  {
    path: `${contentAuthorUrl}/create/${CONTENT_TYPE_RUBRIC}`,
    breadcrumb: `New ${labels[CONTENT_TYPE_RUBRIC]}`,
  },
  {
    path: `${contentAuthorUrl}/edit/${CONTENT_TYPE_RUBRIC}/:contentId`,
    breadcrumb: `Edit ${labels[CONTENT_TYPE_RUBRIC]}`,
  },
]

const ContentAuthorBreadcrumbs = () => {
  const breadcrumbs = push({
    breadcrumb: 'Library',
    key: libraryUrl,
  })(useBreadcrumbs(breadcrumbConfig, { disableDefaults: true })).reverse()

  return <BreadcrumbsRenderer breadcrumbs={breadcrumbs} />
}

export default ContentAuthorBreadcrumbs
