import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import VideoPlayerProvider from 'common/avclub/video/context'
import { ABILITY_STUDENT_INTERFACE, INTERACTIVE_TYPE_VIDEO } from 'core/consts'
import { noop } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext, useId, useMemo } from 'react'
import Figure from '../Figure'
import Content from './Content'

const Video = () => {
  const {
    completed,
    interactiveData: {
      allowAnnotations: interactiveAllowsAnnotations,
      caption,
      captionPosition,
      sceneDescription,
    },
    markComplete,
    previewing,
    uploadsMap,
  } = useContext(interactiveContext)
  const { subsection = {} } = useContext(contentViewerContext) || {}
  const { id: contextContentId } = subsection
  const has = useAbilityChecker()
  const userIsStudent = has(ABILITY_STUDENT_INTERFACE)

  const captionId = useId()

  const video = {
    ccUrl: uploadsMap?.cc?.url,
    dashUrl: '',
    description: sceneDescription,
    fallbackUrl: uploadsMap?.video?.url,
    posterUrl: getImageUrlFromUploadsMap(uploadsMap),
    url: '',
  }

  const handleInteract = useMemo(
    () => (completed ? noop : markComplete),
    [completed, markComplete],
  )

  const allowAnnotations = Boolean(
    userIsStudent && interactiveAllowsAnnotations && contextContentId,
  )

  return (
    <Figure
      caption={caption}
      captionId={captionId}
      captionPosition={captionPosition}
      data-testid="video-figure"
      hideCaption={!caption}>
      <VideoPlayerProvider
        allowAnnotations={allowAnnotations}
        captionId={captionId}
        contextContentId={contextContentId}
        onInteract={handleInteract}
        previewing={previewing}
        video={video}>
        <Content allowAnnotations={allowAnnotations} />
      </VideoPlayerProvider>
    </Figure>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_VIDEO,
}

export default Video
